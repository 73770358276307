import { AnimatePresence, motion } from 'framer-motion';

import ThreadsBar from '../../../ThreadsBar';
import { ThreadObject } from '../../../types';
import TaggedDocumentsList from '../TaggedDocumentList';

interface QuestionSidebarProps {
  taggedDocuments: { doc_id: string; file_name: string }[];
  threads: ThreadObject[];
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
  isLoadingThreads: boolean;
}

const QuestionSidebar = ({
  taggedDocuments,
  threads,
  createNewThread,
  goToThread,
  isLoadingThreads,
}: QuestionSidebarProps) => {
  const taggedItemsExist = taggedDocuments.length > 0;

  return (
    <div className="flex flex-col w-56 min-w-[14rem] bg-white border-r rounded-tl-lg h-full">
      <AnimatePresence>
        {taggedItemsExist && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: '33.333%', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col pt-2 border-b border-dashed pb-2"
          >
            <motion.div className="text-sm font-semibold text-gray-800 pb-1 border-b">
              <p className="px-2">Tagged Items</p>
            </motion.div>
            <motion.div className="px-2 py-2">
              <TaggedDocumentsList taggedDocuments={taggedDocuments} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        layout
        className="bg-gray-50 bg-opacity-50"
        style={{ height: taggedItemsExist ? '66.666%' : '100%' }}
        transition={{ duration: 0.3 }}
      >
        <ThreadsBar
          threads={threads}
          createNewThread={createNewThread}
          goToThread={goToThread}
          isLoadingThreads={isLoadingThreads}
        />
      </motion.div>
    </div>
  );
};

export default QuestionSidebar;
