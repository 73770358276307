import React, { useState } from 'react';

import { faCircleNotch, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import { IMPORTANCY_OPTIONS, CLASSIFICATION_OPTIONS } from 'constants/chronos_table_options';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ChronosDoc, MyOptionType } from 'types';

interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  docId?: string;
}
const FactAdder = ({ handleNewFactCreated, docs, docId }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [selectedImportancy, setSelectedImportancy] = useState<MyOptionType>(null);
  const [selectedClassification, setSelectedClassification] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const [actionDescribed, setActionDescribed] = useState<string>('');
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');

  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingAddNewFact, refetch: refetchAddNewFact } = useQuery(
    ['addFactQuery', selectedDoc?.value],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          action_described: actionDescribed,
          date_of_subject_text: dateOfSubjectMatterText,
          important: selectedImportancy?.value,
          classification: selectedClassification?.value,
          relevance_reason: significance,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/fact/${docId || selectedDoc?.value}`, fetchConfig)
        .then((res) => {
          Swal.fire({
            title: 'New fact created succesfully',
            text: 'A new fact was created succesfully',
            showConfirmButton: false,
            timer: 2500,
          });
          handleNewFactCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while adding new fact',
            text: 'There was an error while adding new fact. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeActionDescribed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActionDescribed(e.target.value);
  };

  const handleAddFact = () => {
    refetchAddNewFact();
  };

  const handleChangeSelectedDoc = (val: MyOptionType) => {
    setSelectedDoc(val);
  };

  const handleChangeSelectImportancy = (val: MyOptionType) => {
    setSelectedImportancy(val);
  };
  const handleChangeSelectClassification = (val: MyOptionType) => {
    setSelectedClassification(val);
  };

  const handleChangeDateOfSubjectMatterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  };

  const handleChangeSignificance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  };

  const docsOptions = docs.map((doc) => {
    return { value: doc.doc_id, label: doc.file_name };
  });

  const disabled = !selectedDoc && !docId;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex flex-col justify-center items-center h-96 gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="text-4xl fa-spin text-brandSecondary" />
        </div>
      ) : (
        <div className="flex flex-col px-6 py-4">
          <input
            onChange={handleChangeActionDescribed}
            className="h-12 pl-3 mt-2 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
            value={actionDescribed}
            placeholder="Fact"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          {!docId && (
            <Select
              options={docsOptions}
              className="outline-none w-full bg-white rounded-md mt-3"
              styles={reactSelectStylesFormFact}
              onChange={handleChangeSelectedDoc}
              value={selectedDoc}
              placeholder={'Select a document'}
            />
          )}
          <input
            onChange={handleChangeDateOfSubjectMatterText}
            className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
            value={dateOfSubjectMatterText}
            placeholder="Date of fact"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <input
            onChange={handleChangeSignificance}
            className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <div className="flex gap-2 mb-4">
            <Select
              options={Object.values(IMPORTANCY_OPTIONS)}
              className="outline-none w-full bg-white rounded-md mt-3"
              styles={reactSelectStylesFormFact}
              onChange={handleChangeSelectImportancy}
              value={selectedImportancy}
              placeholder={'Relevant'}
            />
            <Select
              options={Object.values(CLASSIFICATION_OPTIONS)}
              className="outline-none w-full bg-white rounded-md mt-3"
              styles={reactSelectStylesFormFact}
              onChange={handleChangeSelectClassification}
              value={selectedClassification}
              placeholder={'Classification'}
            />
          </div>
          <Button
            onClick={() => {
              !disabled && handleAddFact();
            }}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
