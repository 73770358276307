import { ReactElement } from 'react';

interface SkeletonOrComponentProps {
  component: ReactElement;
  skeletonHeight?: string;
  isLoading?: boolean;
}

const SkeletonOrComponent = ({ isLoading, component, skeletonHeight = 'h-8' }: SkeletonOrComponentProps) => {
  if (isLoading) {
    return (
      <div className="px-4 py-3 rounded shadow flex flex-col gap-3">
        <div className={`w-full bg-gray-100 rounded-xl animate-pulse ${skeletonHeight}`} />
        <div className={`w-full bg-gray-100 rounded-xl animate-pulse ${skeletonHeight}`} />
        <div className={`w-full bg-gray-100 rounded-xl animate-pulse ${skeletonHeight}`} />
        <div className={`w-4/5 bg-gray-100 rounded-xl animate-pulse ${skeletonHeight}`} />
      </div>
    );
  }

  return component;
};

export default SkeletonOrComponent;
