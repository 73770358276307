import { useState } from 'react';

import { trackCustomEvent } from 'analytics/Mixpanel';
import Button from 'components/atoms/Button';
import { ChronosDoc, Filters } from 'types';

import MultiSelectFilter from '../../../../components/Filters/FactTableFilters/MultiselectFilter';
import MultiSelectFilterDocs from '../../../../components/Filters/FactTableFilters/MultiselectFilterDocs';
interface OptionType {
  value: string | boolean;
  label: string;
}

const FactsFilterPopup = ({
  activeFilters,
  allDocuments,
  setFilters,
  handleClose,
}: {
  activeFilters: Filters;
  allDocuments: ChronosDoc[];
  setFilters: (v: any) => void;
  handleClose: () => void;
}) => {
  const filterDict: { [id: string]: { label: string; value: string | boolean }[] } = {};
  Object.keys(activeFilters).forEach((key) => {
    if (!activeFilters[key]) return;
    filterDict[key] = activeFilters[key].map((v) => ({ label: v.label, value: v.value }));
  });

  const [documentFilters, setDocumentFilters] = useState<OptionType[]>(filterDict['source_doc'] || []);
  const [relevanceFilters, setRelevanceFilters] = useState<OptionType[]>(filterDict['important'] || []);
  const [classificationFilters, setClassificationFilters] = useState<OptionType[]>(filterDict['classification'] || []);

  const handleSubmit = () => {
    const newFilters = { ...activeFilters };
    newFilters['source_doc'] = documentFilters;
    newFilters['important'] = relevanceFilters;
    newFilters['classification'] = classificationFilters;

    trackCustomEvent('Filters applied');
    setFilters(newFilters);
    handleClose();
  };
  const handleClear = () => {
    setFilters([]);
    handleClose();
  };

  return (
    <div className="flex flex-col gap-2 text-sm px-8 py-8">
      <div className="flex items-center justify-center gap-2">
        <p className="w-24 border-r mr-2">Documents: </p>
        <MultiSelectFilterDocs
          selectedOptions={documentFilters}
          setSelectedOptions={setDocumentFilters}
          allDocuments={allDocuments}
          className="text-xs w-64"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="w-24 border-r mr-2">Relevance:</p>
        <MultiSelectFilter
          selectedOptions={relevanceFilters}
          setSelectedOptions={setRelevanceFilters}
          filterOption="important"
          className="text-xs w-64"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="w-24 border-r mr-2">Classification:</p>
        <MultiSelectFilter
          selectedOptions={classificationFilters}
          setSelectedOptions={setClassificationFilters}
          filterOption="classification"
          className="text-xs w-64"
        />
      </div>
      <div className="flex justify-between px-4 mt-6 mb-4">
        <Button type="delete" rounded="md" text="Clear Filters" onClick={handleClear} />
        <Button type="primary" rounded="md" text="Apply Filters" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FactsFilterPopup;
