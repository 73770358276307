import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface UpdateCaseParameters {
  caseId: string | null;
  updateDetailsActive: boolean;
  caseIssues: string;
  caseParties: string;
  caseContext: string;
  caseTimePeriod: string;
}

const useUpdateCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const mutation = useMutation(
    ({ caseId, updateDetailsActive, caseIssues, caseParties, caseContext, caseTimePeriod }: UpdateCaseParameters) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          isUpdateSynopsis: updateDetailsActive,
          caseIssues: updateDetailsActive ? caseIssues : undefined,
          caseParties: updateDetailsActive ? caseParties : undefined,
          caseContext: updateDetailsActive ? caseContext : undefined,
          caseTimePeriod: updateDetailsActive ? caseTimePeriod : undefined,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/update/${caseId}`, fetchConfig)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
          throw err; // Re-throw to allow react-query to catch and handle the error.
        });
    },
  );

  return mutation;
};

export default useUpdateCase;
