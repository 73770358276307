import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { ChronosFact } from 'types';

interface ChronosFactResponse {
  facts: ChronosFact[];
}

function useDocumentFacts(docId: string | null | undefined, caseId: string | null | undefined) {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<ChronosFactResponse, Error>(
    ['userFacts', docId, caseId],
    async () => {
      const response = await fetch(
        `${APIBaseChronos}/client/case/fact/${caseId}/documentFacts?docId=${docId}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: false },
  );

  return { data, error, isLoading, isFetching, refetch };
}

export default useDocumentFacts;
