import React, { useState, useEffect } from 'react';

const Doc = ({
  title,
  relevantFacts,
  top,
  onClick,
  delay,
}: {
  title: string;
  relevantFacts: number;
  delay: number;
  onClick: () => void;
  top?: boolean;
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  const formatTitle = (title: string) => {
    if (title.length > 45) {
      return title.slice(0, 45) + '...';
    }
    return title;
  };

  return (
    <div
      className={`hover:scale-105 cursor-pointer shadow-sm border border-blue-600 border-opacity-40 py-3 px-3 rounded flex justify-between transform transition-all duration-500 ${
        visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
      } ${top ? 'bg-blue-50' : 'bg-white'}`}
      style={{ transitionDelay: `${delay}ms` }}
      onClick={onClick}
    >
      <p className="italic">{formatTitle(title)}</p>
      <p className="text-gray-500 text-xs">
        <span className="text-blue-500 font-semibold text-sm">{relevantFacts}</span> Relevant Facts
      </p>
    </div>
  );
};

const RelevantDocs = ({
  docs,
  onClick,
}: {
  docs: { doc_id: string; event_count: number; file_name: string; important_event_count: number }[];
  onClick: (docId: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      {docs.map((doc, index) => (
        <Doc
          key={index}
          title={doc.file_name}
          relevantFacts={doc.important_event_count}
          delay={index * 100}
          onClick={() => onClick(doc.doc_id)}
          top={index === 0}
        />
      ))}
    </div>
  );
};

export default RelevantDocs;
