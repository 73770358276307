import moment from 'moment';

const computeCaseMessage = (
  inQueue: boolean,
  queuePosition: number | null,
  estimateDate: Date | null,
  queueEstimateDate: Date | null,
): string => {
  if (inQueue && queuePosition !== null) {
    return (
      `Your case is in queue position ${queuePosition}` +
      (queueEstimateDate
        ? `, estimated time to begin processing: ${moment
            .tz(queueEstimateDate, 'Europe/London')
            .format('Do MMMM HH:mm A')}`
        : '')
    );
  }
  if (inQueue && queuePosition === null) {
    return 'Queue position being calculated';
  }
  if (!inQueue && estimateDate) {
    return `Estimated time of completion for Chronology: ${moment
      .tz(estimateDate, 'Europe/London')
      .format('Do MMMM HH:mm A')}`;
  }
  return 'Case processing. Time estimate being calculated';
};

export default computeCaseMessage;
