import Lottie, { ILottie } from '@lottielab/lottie-player/react';

interface Props {
  animationData: any;
  playing?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  className?: string;
  lottieRef?: React.RefObject<ILottie>;
}

const LottieAnimation = ({
  animationData,
  playing = true,
  autoplay = true,
  loop = false,
  className,
  lottieRef,
}: Props) => {
  return (
    <Lottie
      playing={playing}
      ref={lottieRef}
      lottie={animationData}
      autoplay={autoplay}
      loop={loop}
      className={className}
    />
  );
};

export default LottieAnimation;
