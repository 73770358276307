import { AnimatePresence, motion } from 'framer-motion';

const ToastDrawer = ({ isOpen, toggleDrawer, children }: any) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className="fixed bottom-4 right-2 border shadow-xl w-[525px] max-w-full bg-white rounded-lg p-2 z-50"
        >
          <div className="flex flex-col">
            <button
              className="absolute top-1 right-2 text-xl text-gray-600 hover:text-gray-800 z-10"
              onClick={toggleDrawer}
            >
              ✖
            </button>
            <AnimatePresence mode="wait">
              <motion.div
                key={children.key}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {children}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ToastDrawer;
