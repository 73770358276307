import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Viewer } from '@react-pdf-viewer/core';
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import fileLoadingAnimation from 'assets/animations/FileLoading.json';
import LottieAnimation from 'components/atoms/Lottie';

import { FloatingControls, HeaderControls } from './Controls';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface HighlightAreaWithFactId extends HighlightArea {
  factId: string;
}

interface PDFViewerProps {
  fileUrl: string | null | undefined;
  loading: boolean;
  initialPage: number;
  handleClosePDFViewer?: (() => void) | null;
  highlights?: HighlightAreaWithFactId[];
  highlightInstance?: any;
  closeButtonText?: string;
  defaultZoom?: number;
  title?: string;
  type: 'full' | 'inline';
}

const PDFViewer = ({
  title,
  type,
  fileUrl,
  loading,
  initialPage,
  highlightInstance,
  highlights,
  handleClosePDFViewer,
  closeButtonText = 'Back',
  defaultZoom = 1.1,
}: PDFViewerProps) => {
  let highlightPluginInstance = highlightInstance;
  if (!highlightInstance && highlights) {
    const renderHighlights = (props: RenderHighlightsProps) => {
      return (
        <div>
          {highlights
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                className={`highlight-area bg-yellow-300 opacity-40 z-10 cursor-pointer`}
                style={Object.assign({}, props.getCssProperties(area, props.rotation), { pointerEvents: 'auto' })}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></div>
            ))}
        </div>
      );
    };

    highlightPluginInstance = highlightPlugin({
      renderHighlights: renderHighlights,
      trigger: Trigger.None,
    });
  }

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const { CurrentPageInput, GoToPreviousPage, GoToNextPage, GoToFirstPage, GoToLastPage } =
    pageNavigationPluginInstance;

  return (
    <>
      <div className="flex flex-col h-full">
        <div
          className={`px-2 flex gap-4 items-center border-b shadow-sm py-2 ${
            type === 'inline' ? 'justify-between' : 'justify-start'
          }`}
        >
          <div
            className="cursor-pointer flex gap-2 items-center border px-2 bg-gray-100 rounded-full hover:bg-gray-200"
            onClick={() => handleClosePDFViewer && handleClosePDFViewer()}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="w-3 h-3 py-1 xl:py-0" />
            <p className="hidden xl:block">{closeButtonText}</p>
          </div>

          {type === 'inline' && (
            <HeaderControls
              GoToFirstPage={GoToFirstPage}
              GoToPreviousPage={GoToPreviousPage}
              CurrentPageInput={CurrentPageInput}
              GoToNextPage={GoToNextPage}
              GoToLastPage={GoToLastPage}
              ZoomIn={ZoomIn}
              ZoomOut={ZoomOut}
            />
          )}

          {type === 'full' && title && (
            <div className="font-medium">{title.length > 60 ? title.slice(0, 60) + '...' : title}</div>
          )}
        </div>
        <div className="overflow-hidden pt-2 relative h-full">
          {loading ? (
            <div className="flex flex-col items-center justify-center h-full relative">
              <span className="text-gray-500 absolute top-40 text-lg">Loading Document...</span>
              <LottieAnimation autoplay loop animationData={fileLoadingAnimation} className=" h-2/3 w-2/3" />
            </div>
          ) : (
            <>
              {fileUrl && (
                <Viewer
                  fileUrl={fileUrl}
                  plugins={[pageNavigationPluginInstance, highlightPluginInstance, zoomPluginInstance]}
                  initialPage={initialPage - 1} // package 0 indexes pages
                  defaultScale={defaultZoom}
                />
              )}

              {type === 'full' && (
                <FloatingControls
                  GoToFirstPage={GoToFirstPage}
                  GoToPreviousPage={GoToPreviousPage}
                  CurrentPageInput={CurrentPageInput}
                  GoToNextPage={GoToNextPage}
                  GoToLastPage={GoToLastPage}
                  ZoomIn={ZoomIn}
                  ZoomOut={ZoomOut}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PDFViewer;
