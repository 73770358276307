interface PlainTextContentProps {
  html: string;
  maxLength: number;
}

export const PlainTextContent: React.FC<PlainTextContentProps> = ({ html, maxLength = 100 }) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  let textContents = Array.from(doc.body.childNodes)
    .map((node) => node.textContent?.trim()) // Extract the text content of each node
    .filter((text): text is string => Boolean(text)); // Remove any null or undefined text content

  // Join the text content, separating each with a comma
  let joinedText = textContents.join(', ');

  // If the text exceeds the maxLength, truncate and add an ellipsis
  if (joinedText.length > maxLength) {
    joinedText = `${joinedText.substring(0, maxLength - 3)}...`;
  }

  return <p className="break-all">{joinedText}</p>;
};

export const renderHighlightedTextWithLinks = (text: string) => {
  // Regex to match @[****](XXXXX)
  const regex = /@\[(.*?)\]\(.*?\)/g;
  const parts = text.split(regex);
  const partsWithHighlights = parts.map((part, index) => {
    if (index % 2 === 1) {
      // This means it's the matched group which is ****
      return (
        <span key={`${index}-highlight`} className="bg-yellow-200 px-1 rounded">
          {part}
        </span>
      );
    }
    return part; // Normal text not inside @[****]
  });

  // Go through partsWithHighlights find  which is a link and add it to an output
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Loop throigh partsWithHighlights, if the part is a string, split it on the link regex,
  // and add the parts to the output, but format the link part
  // If the part is not a string, just add it to the output
  const output = partsWithHighlights.map((part) => {
    if (typeof part === 'string' && part.match(urlRegex)) {
      return part.split(urlRegex).map((part, index) => {
        if (index % 2 === 1) {
          return (
            <a key={`${index}-link`} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500">
              {part}
            </a>
          );
        }
        return part;
      });
    }
    return part;
  });
  return output;
};
