import React from 'react';

import { faMagnifyingGlass, faQuestion, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { KimMode } from '../../../types';

interface ModeSelectorProps {
  mode: KimMode;
  setMode: (mode: KimMode) => void;
  setPlaceholder: (placeholder: string) => void;
}

const ModeSelector: React.FC<ModeSelectorProps> = ({ mode, setMode, setPlaceholder }) => {
  return (
    <div className="mt-2 bg-white border rounded-lg flex justify-between text-sm w-full">
      <div className="w-1/3">
        <ModeOption
          mode="question_flow"
          currentMode={mode}
          icon={faQuestion}
          title="Question"
          description="Ask a question and get an answer."
          onClick={() => {
            setMode('question_flow');
            setPlaceholder('Tell me about X...');
          }}
        />
      </div>
      <div className="border-x w-1/3">
        <ModeOption
          mode="search_flow"
          currentMode={mode}
          icon={faTable}
          title="Extract"
          description="Extract information/entities into a report."
          onClick={() => {
            setMode('search_flow');
            setPlaceholder('Extract all mentions of Y into a report...');
          }}
        />
      </div>
      <div className="w-1/3">
        <ModeOption
          mode="task_flow"
          currentMode={mode}
          icon={faMagnifyingGlass}
          title="Draft"
          description="Create a draft document backed by facts."
          onClick={() => {
            setMode('task_flow');
            setPlaceholder('Prepare me a draft letter of [X] about [Y]...');
          }}
        />
      </div>
    </div>
  );
};

interface ModeOptionProps {
  mode: string;
  currentMode: string;
  icon: any;
  title: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
}

const ModeOption: React.FC<ModeOptionProps> = ({
  mode,
  currentMode,
  icon,
  title,
  description,
  onClick,
  disabled = false,
}) => {
  const baseClasses =
    'transition-all duration-300 h-[90%] block text-left px-4 py-2 m-1 rounded-lg border-brandSecondary border shadow-sm';
  const activeClasses = mode === currentMode ? 'bg-gray-100 border-opacity-40 shadow-sm' : '';
  const inactiveClasses = mode !== currentMode ? 'border-opacity-0' : '';
  const hoverClasses = disabled ? '' : 'hover:bg-gray-100 cursor-pointer';
  const disabledClasses = disabled ? 'cursor-not-allowed text-gray-400' : '';

  return (
    <div
      className={`${baseClasses} ${activeClasses} ${hoverClasses} ${disabledClasses} ${inactiveClasses}`}
      onClick={disabled ? undefined : onClick}
    >
      <p>
        <FontAwesomeIcon icon={icon} className="pr-1 w-3 h-3" />
        {title}
      </p>
      <p className="text-gray-500 text-xs">{description}</p>
    </div>
  );
};

export default ModeSelector;
