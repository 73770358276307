import { ConfidenceLevelObject } from '../../../types';

const ConfidenceTooltip = ({ confidenceLevel }: { confidenceLevel: ConfidenceLevelObject }) => {
  return (
    <div>
      <div className={`h-24 flex justify-center items-center ${confidenceLevel.tooltipColor} bg-opacity-80`}></div>
      <div className="px-8 pb-10">
        <p className="font-semibold text-lg pb-2 pt-4">{confidenceLevel.text} Confidence</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>{confidenceLevel.tooltip}</p>
          <p className="text-sm text-gray-500 border border-x-0 border-b-0 pt-4">
            All Kim answers have a confidence level, ranging from High to Low.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfidenceTooltip;
