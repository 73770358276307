import styled from 'styled-components';

export const ContainerPanel = styled.div`
  left: '0px';
  width: ${({ compressed }) => (compressed ? '80px !important;' : '190px !important;')};
  flex-shrink: 0;
  position: sticky;
  padding-right: 10px;
  background: #0d1d54;
  transition: all 0.7s;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  overflow: hidden;
  background: linear-gradient(to bottom, #013e45 0%, #013e45 40%, #31c4ac 100%);
`;

export const ContainerMenu = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
  padding-bottom: 10px;
`;
