import { faCircleNotch, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { ChronosChronology } from 'types';

interface AddToChronologyModalContentProps {
  isLoadingChronologies: boolean;
  chronologies: ChronosChronology[];
  handleSelectChronology: (chronologyId: string) => void;
}

const AddToChronologyModalContent = ({
  isLoadingChronologies,
  chronologies,
  handleSelectChronology,
}: AddToChronologyModalContentProps) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
  };

  return (
    <div className="p-4 overflow-auto flex items-center justify-start flex-col h-96 overflow-y-scroll">
      {isLoadingChronologies ? (
        <div className="flex justify-center items-center h-full">
          <FontAwesomeIcon icon={faCircleNotch} className="text-gray-600 fa-spin" />
        </div>
      ) : (
        <div className="w-full px-6">
          <p className="text-sm mb-4 pb-1 text-gray-700 border-b">Select an existing chronology to add facts to.</p>
          <div className="w-full flex flex-col justify-between gap-4">
            {chronologies.map((chronology) => {
              return (
                <div
                  key={chronology.chronology_id}
                  className="border py-2 px-4 rounded-lg text-sm flex justify-between items-center shadow bg-gray-50"
                >
                  <div className="flex flex-col">
                    <p className="font-medium">
                      {chronology.latest_title.length > 40
                        ? chronology.latest_title.slice(0, 40) + '...'
                        : chronology.latest_title}
                    </p>
                    <p className="text-xs text-gray-500">Created {formatDate(chronology.created_date)}</p>
                  </div>
                  <button
                    className="bg-buttonPrimary text-white px-2 py-1 rounded-lg text-xs hover:bg-buttonPrimary-hover"
                    onClick={() => handleSelectChronology(chronology.chronology_id)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1" /> Add
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToChronologyModalContent;
