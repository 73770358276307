import { useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const useDocumentNavigation = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const goToDocReference = useCallback(
    (newDocId: string, newEventId: string, pageNumber: number, navigateToDocViewer: boolean = false) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('docId', newDocId);
      searchParams.set('eventId', newEventId);
      searchParams.set('docPageNumber', pageNumber.toString());
      navigateToDocViewer
        ? navigate(`/app/chronos/case-editor/data/documents?${searchParams}`)
        : navigate(`${pathname}?${searchParams}`, { replace: true });
    },
    [navigate, pathname, search],
  ); // Dependencies

  return { goToDocReference };
};
