import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig'; // Ensure this path is correct
import { useMutation } from 'react-query';

import { UserObject } from '../types/user'; // Ensure this path is correct

interface UserUpdateMatterProps {
  matterId: string;
  matterName: string;
  matterDescription: string;
  matterCode: string;
  selectedUsers: UserObject[];
}

const useUpdateMatter = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const updateMatter = async ({
    matterId,
    matterName,
    matterDescription,
    matterCode,
    selectedUsers,
  }: UserUpdateMatterProps) => {
    const fetchConfig = getFetchConfig({
      method: 'PUT',
      data: {
        matterName,
        matterDescription,
        matterCode,
        userIds: selectedUsers.map((x) => x.user_id),
        matterId,
      },
    });

    const response = await fetch(`${APIBaseChronos}/client/case/matter/${matterId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error updating matter');
    }
    return response.json();
  };

  return useMutation(updateMatter);
};

export default useUpdateMatter;
