import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView } from 'analytics/Mixpanel';
import { useSearchParams } from 'react-router-dom';
import { ChronosDoc } from 'types';

import DocumentRow from './components/DocumentRow';
import DocumentEditorToolbar from './DocumentEditorToolbar';
import useFetchDocs from './hooks/useFetchDocs';
import DocViewer from '../DocViewer/FullDocViewer';

const DocumentEditor = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';
  const docPageNumber = Number(searchParams.get('docPageNumber'));
  const docId = searchParams.get('docId');
  const factTotal = searchParams.get('factTotal');

  const [globalFilter, setGlobalFilter] = useState('');
  const [appliedGlobalFilter, setAppliedGlobalFilter] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [totalPages, setTotalPages] = useState(0);
  const [filteredPages, setFilteredPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [title, setTitle] = useState('');
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isDocViewerFullyOpen, setIsDocViewerFullyOpen] = useState(false);

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
      // Add a slight delay before setting isDocViewerFullyOpen to true
      setTimeout(() => setIsDocViewerFullyOpen(true), 300);
    } else {
      setIsDocViewerFullyOpen(false);
      // Add a slight delay before closing the doc viewer
      setTimeout(() => setIsDocViewerOpen(false), 300);
    }
  }, [docId]);

  const {
    data: responseDocs,
    isFetching: isFetchingDocsTable,
    isLoading: isLoadingDocsTable,
  } = useFetchDocs(caseId, pagination.pageIndex + 1, pagination.pageSize, appliedGlobalFilter);

  const onSearchCall = () => {
    setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    setAppliedGlobalFilter(globalFilter);
  };

  const clearSearch = async () => {
    setGlobalFilter('');
    setAppliedGlobalFilter('');
    setFilteredPages(0);
  };

  useEffect(() => {
    trackPageView('Documents Page');
  }, []);

  useEffect(() => {
    // Set pagination vars only if they haven't been set before
    if (totalPages === 0 && responseDocs?.pagination?.totalPages) {
      setTotalPages(responseDocs.pagination.totalPages);
    }
    if (totalDocs === 0 && responseDocs?.pagination?.totalCount) {
      setTotalDocs(responseDocs.pagination.totalCount);
    }
    if (processedCount === 0 && responseDocs?.pagination?.processedCount) {
      setProcessedCount(responseDocs.pagination.processedCount);
    }

    if (responseDocs?.pagination?.filteredPages) {
      setFilteredPages(responseDocs.pagination.filteredPages);
    }

    // eslint-disable-next-line
  }, [responseDocs]);

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  return (
    <div className="flex h-full w-full">
      {!isDocViewerFullyOpen && (
        <div
          className="flex flex-col h-full transition-all duration-300 ease-in-out"
          style={{ width: isDocViewerOpen ? '0%' : '100%', opacity: isDocViewerOpen ? 0 : 1 }}
        >
          <DocumentEditorToolbar
            processedCount={processedCount}
            totalDocs={totalDocs}
            openSettingsModal={openSettingsModal}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
            totalPages={filteredPages > 0 ? filteredPages : totalPages}
            goToPage={goToPage}
            currentPage={pagination.pageIndex}
            prevPage={prevPage}
            nextPage={nextPage}
          />
          <div className="overflow-y-scroll mb-6">
            {isLoadingDocsTable ||
              (isFetchingDocsTable && (
                <div className="w-full h-full min-h-[500px] flex items-center justify-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
                </div>
              ))}
            <div className="w-full flex flex-col gap-3 py-1">
              {responseDocs &&
                responseDocs.docs &&
                responseDocs.docs.length > 0 &&
                responseDocs.docs.map((doc: ChronosDoc) =>
                  doc.is_removed ? (
                    <div key={doc.doc_id}></div>
                  ) : (
                    <DocumentRow
                      key={doc.doc_id}
                      doc={doc}
                      caseId={caseId || ''}
                      page={pagination.pageIndex}
                      pageSize={pagination.pageSize}
                      searchQuery={appliedGlobalFilter}
                      setTitle={setTitle}
                    />
                  ),
                )}
            </div>
          </div>
        </div>
      )}

      <div
        className={`transition-all duration-300 ease-in-out ${
          isDocViewerOpen ? 'w-full opacity-100 translate-x-0' : 'w-0 opacity-0 translate-x-full'
        } bg-white overflow-hidden`}
      >
        {docId && (
          <DocViewer
            title={title}
            docId={docId}
            caseId={caseId || ''}
            pageNumber={docPageNumber}
            factTotal={factTotal}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentEditor;
