import React from 'react';

import StatisticItem from 'components/atoms/StatisticItem';

interface StatisticsGridProps {
  isFetching: boolean;
  data:
    | {
        pageCountTotal: number;
        runCountTotal: number;
        chronologyCountTotal: number;
        eventCountTotal: number;
      }
    | undefined;
}

const StatisticsGrid: React.FC<StatisticsGridProps> = ({ isFetching, data }) => (
  <div className="w-full flex flex-row gap-6">
    <StatisticItem
      title="Number of pages processed"
      value={data ? data.pageCountTotal : null}
      isFetching={isFetching}
    />
    <StatisticItem title="Number of runs submitted" value={data ? data.runCountTotal : null} isFetching={isFetching} />
    <StatisticItem
      title="Number of chronologies created"
      value={data ? data.chronologyCountTotal : null}
      isFetching={isFetching}
    />
    <StatisticItem
      title="Number of events extracted"
      value={data ? data.eventCountTotal : null}
      isFetching={isFetching}
    />
  </div>
);

export default StatisticsGrid;
