interface ButtonProps {
  onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  text: string;
  className?: string;
  icon?: React.ReactNode;
  size?: 'xs' | 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'delete' | 'brand';
  rounded?: 'sm' | 'base' | 'md' | 'lg';
  disabled?: boolean;
  hideTextSmallScreen?: boolean;
}

const Button = ({
  onClick,
  icon,
  text,
  className,
  size,
  type,
  rounded,
  disabled,
  hideTextSmallScreen,
  ...props
}: ButtonProps) => {
  // Add loading in

  let defaultClass = '';
  if (className) {
    defaultClass = className;
  }
  if (!className) {
    defaultClass += 'flex items-center font-normal w-fit';
    if (type === 'primary') {
      defaultClass += ' bg-buttonPrimary hover:bg-buttonPrimary-hover text-white font-bold py-1 px-4';
    } else if (type === 'secondary') {
      defaultClass += ' bg-buttonSecondary hover:bg-buttonSecondary-hover text-gray-800 py-1 px-4 border';
    } else if (type === 'delete') {
      defaultClass += ' text-red-600 border-red-600 hover:bg-red-50 border shadow px-4 py-1';
    } else if (type === 'brand') {
      defaultClass += ' bg-brandSecondary hover:bg-brandSecondary-hover text-white font-bold py-1 px-4';
    }
  }
  if (size === 'small') {
    defaultClass += ' text-sm';
  } else if (size === 'large') {
    defaultClass += ' text-lg';
  } else if (size === 'medium') {
    defaultClass += ' text-base';
  } else if (size === 'xs') {
    defaultClass += ' text-xs';
  }

  if (rounded) {
    if (rounded === 'base') defaultClass += ' rounded';
    else defaultClass += ` rounded-${rounded}`;
  }

  return (
    <button className={defaultClass} onClick={onClick} disabled={disabled} {...props}>
      {icon ? icon : null}
      {hideTextSmallScreen ? <span className="xl:inline hidden">{text}</span> : text}
    </button>
  );
};

export default Button;
