import React, { useState } from 'react';

import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { ReactComponent as ReactInfoYellow } from 'assets/info_yellow.svg';
import TooltipIcon from 'components/atoms/TooltipIcon';
import Accordion from 'components/molecules/Accordion';
import { IMPORTANCY_OPTIONS } from 'constants/chronos_table_options';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { ChronosDocumentCoordinate, ChronosFact } from 'types';

import CalendarEditPopup from './CalendarPopup';
import CommentPopup from './Comments';
import DropdownInput from './DropdownInput';
import { PlainTextContent } from './helpers';
import { renderHighlightedTextWithLinks } from './helpers';
import TextInput from './TextInput';
import { useDocumentNavigation } from '../../../../FactsEditor/utils/navigationUtils';

// Interfaces
interface FactDropdownProps {
  fact: ChronosFact;
  userId: string;
  compressedVersion?: boolean;
  reference?: number;
  hideRelevanceIndicator?: boolean;
  setIsDateDirty?: (v: boolean) => void;
  updateFactData?: (eventId: string, data: Partial<ChronosFact>) => void;
}
interface FactDetailProps {
  fact: ChronosFact;
  significanceText: string;
  updateSignificance: (v: string) => void;
  relevanceText: string;
  updateRelevance: (v: string) => void;
  userId: string;
  compressedVersion?: boolean;
}
interface FactTitleProps {
  open: boolean;
  fact: ChronosFact;
  factText: string;
  updateFact: (v: string) => void;
  dateText: string;
  updateDate: (v: string) => void;
  compressedVersion?: boolean;
  reference?: number;
  hideRelevanceIndicator?: boolean;
  setIsDateDirty?: (v: boolean) => void;
}

const FactDetail = ({
  fact,
  significanceText,
  updateSignificance,
  relevanceText,
  updateRelevance,
  userId,
  compressedVersion = false,
}: FactDetailProps) => {
  const uploadDate = new Date(fact.date_uploaded);
  const { goToDocReference } = useDocumentNavigation();

  return (
    <div className={`${compressedVersion ? 'text-xs' : 'text-sm'}`}>
      <div className="border border-dashed border-blue-200 border-x-0 border-t-0 mt-2"></div>

      <div className="flex gap-4 py-4 pr-4 pl-2">
        <div className="w-4/5">
          <div className="font-semibold mb-1 px-2 flex gap-1 items-center text-gray-800">
            Significance
            <TooltipIcon
              tooltipId={`significance-tooltip-${fact.event_id}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from."
              className="mr-2 w-4 h-4 cursor-pointer"
            />
          </div>
          <div className="text-gray-800 px-2">
            <TextInput
              editable={true}
              entryId={fact.event_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={significanceText}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${fact.event_id}`}
              updateState={updateSignificance}
              location="Fact"
            />
          </div>
        </div>

        <div className="border border-dashed border-blue-200 border-y-0 border-r-0 pl-6">
          <div className="w-36">
            <div className="font-semibold mb-1 flex gap-1 items-center text-gray-800">
              Relevant
              <TooltipIcon
                tooltipId={`relevance-tooltip-${fact.event_id}`}
                tooltipContent="Is this fact relevant to the key case issues."
                className="mr-2 w-4 h-4 cursor-pointer"
                place="left"
              />
            </div>
            <div
              className={`${
                relevanceText === 'Yes' ? 'text-green-700' : relevanceText === 'No' ? 'text-red-500' : 'text-orange-400'
              }`}
            >
              <DropdownInput
                entryFieldKey={'important'}
                entryFieldValue={relevanceText}
                endpointToUpdate={`${APIBaseChronos}/client/case/fact/${fact.event_id}`}
                options={IMPORTANCY_OPTIONS}
                updateRelevance={updateRelevance}
              />
            </div>

            <div className="font-semibold mt-2 mb-1 flex gap-1 items-center text-gray-800">
              Classification
              <TooltipIcon
                tooltipId={`classification-tooltip-${fact.event_id}`}
                tooltipContent="Fact classification. One of Primary, Secondary, Future or Litigation."
                className="mr-2 w-4 h-4 cursor-pointer"
                place="left"
              />
            </div>
            <div>
              {fact.classification ? fact.classification.charAt(0).toUpperCase() + fact.classification.slice(1) : ''}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-dashed border-x-0 border-t-0 border-blue-200"></div>

      <div className="py-4 pr-4 pl-2 ">
        <div className="font-semibold mb-1 px-2 text-gray-800">Documents</div>
        <div>
          {fact.documents.map((document: ChronosDocumentCoordinate, index: number) => {
            const pageNumber = document?.coordinate_details?.length > 0 ? document?.coordinate_details?.[0]?.n_page : 1;
            const file = document?.file_name?.split('.')?.filter(Boolean)[0];
            return (
              <div
                key={`${document.doc_id}-${index}`}
                className="flex px-2 gap-4 items-baseline justify-between border border-x-0 border-t-0 pb-1 border-dashed"
              >
                <div className="flex items-baseline gap-4">
                  <div
                    key={document.doc_id}
                    className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden"
                    onClick={() => {
                      if (compressedVersion) {
                        goToDocReference(document.doc_id, fact.event_id, pageNumber);
                      } else {
                        goToDocReference(document.doc_id, fact.event_id, pageNumber, true);
                      }
                    }}
                  >
                    {file}
                  </div>
                  <span className="px-2 rounded-sm bg-brandTertiary bg-opacity-30 text-gray-600">
                    {document.document_type}
                  </span>
                  {document.document_date_text ? (
                    <span className=" text-gray-600 text-xs">
                      (<span className="text-xs">{document.document_date_text})</span>
                    </span>
                  ) : null}
                </div>

                <div className="flex items-baseline gap-6">
                  <span className="text-xs text-gray-600 italic">
                    (uploaded: {`${uploadDate.getDate()}/${uploadDate.getMonth() + 1}/${uploadDate.getFullYear()}`})
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div className="font-semibold mt-3 mb-1 px-2 text-gray-800">Source Text</div>
          <div className="text-gray-500 px-2">
            <PlainTextContent html={fact.source_text} maxLength={600} />
          </div>
        </div>
      </div>

      <div className="border-2  border-x-0 border-t-0 border-blue-200"></div>

      <div className="flex justify-between px-4 bg-slate-100 gap-10 rounded-b-lg">
        <div>
          <div className="font-semibold pt-2 pb-1">Comments</div>
          <div>
            {fact.first_comment ? (
              renderHighlightedTextWithLinks(fact.first_comment)
            ) : (
              <span className="text-gray-500 font-light">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup thread_id={fact.event_id} comments_count={fact.comments_count || 0} currentUserId={userId} />
        </div>
      </div>
    </div>
  );
};

const FactTitle = ({
  open,
  fact,
  factText,
  updateFact,
  dateText,
  updateDate,
  reference,
  hideRelevanceIndicator,
  setIsDateDirty,
  compressedVersion = false,
}: FactTitleProps) => {
  const { goToDocReference } = useDocumentNavigation();

  const referenceDigits = reference ? Math.abs(reference).toString().length : 2;
  const referencePosition = referenceDigits === 1 ? 'right-5' : referenceDigits === 2 ? 'right-4' : 'right-3';

  const RelevanceIndicator = ({ importance }: { importance: string }) => {
    const getRelevanceInfo = () => {
      switch (importance) {
        case 'Yes':
          return { text: 'Relevant', color: 'text-green-800', icon: faCircleCheck };
        case 'May be relevant':
          return { text: 'Maybe Relevant', color: 'text-orange-400', icon: faCircleQuestion };
        default:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
      }
    };

    const { text, color, icon } = getRelevanceInfo();

    return (
      <p className={`text-xs w-32 flex justify-end items-center gap-2 ${color}`}>
        <FontAwesomeIcon icon={icon} />
        <span>{text}</span>
      </p>
    );
  };

  return (
    <div className="flex items-center justify-between font-normal w-full">
      <div className={`px-2 pl-2 w-full ${compressedVersion ? 'p-[6px]' : 'p-2'}`}>
        <div className="flex justify-between w-full">
          <div className={`font-semibold flex gap-2 items-center ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            <CalendarEditPopup
              dateText={dateText}
              entryFieldKey="date_of_subject_text"
              updateDate={updateDate}
              compressedVersion={compressedVersion}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${fact.event_id}`}
              setIsDateDirty={setIsDateDirty}
              location="Fact"
            />

            {dateText ? dateText : '(Date Unknown)'}

            {!!fact.date_of_subject_is_ambiguous && (
              <>
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                  data-tooltip-content={
                    fact.date_of_subject_is_ambiguous_text || 'Date format is ambiguous, please check'
                  }
                  className="text-gray-700 text-sm cursor-pointer"
                  style={{ color: '#E3B336' }}
                />
                <Tooltip
                  opacity={1}
                  id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              </>
            )}
            {!!fact.is_future_date && !fact.date_of_subject_is_ambiguous && (
              <>
                <ReactInfo
                  data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}`}
                  data-tooltip-content="Hypothetical future date"
                  className="text-gray-700 text-sm cursor-pointer"
                  style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                />
                <Tooltip opacity={1} id={`fact-date-text-tooltip-${fact.event_id}`} style={TooltipStyles} />
              </>
            )}
          </div>

          <div className="text-blue-600 text-xs">
            {fact.documents.map((doc, index) => {
              if (compressedVersion && index === 1) return <div key={`${index}-ellipsis`}>...</div>;
              if (compressedVersion && index > 1) return <div key={`${index}-ellipsis`}></div>;
              if (index === 2) return <div key={`${index}-ellipsis`}>...</div>;
              if (index > 2) return <div key={`${index}-ellipsis`}></div>;
              return (
                <span key={`${doc.doc_id}-${index}`}>
                  <span
                    data-tooltip-id={`fact-document-tooltip-${fact.event_id}-${doc.doc_id}`}
                    data-tooltip-content={doc.file_name}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (compressedVersion) {
                        goToDocReference(doc.doc_id, fact.event_id, doc?.coordinate_details?.[0]?.n_page || 1);
                      } else {
                        goToDocReference(doc.doc_id, fact.event_id, doc?.coordinate_details?.[0]?.n_page || 1, true);
                      }
                    }}
                  >
                    {doc.file_name ? doc.file_name.slice(0, 20).trim() + '...' : ''}
                  </span>
                  {doc.document_type && (
                    <span className="rounded-sm text-gray-600 bg-brandTertiary bg-opacity-20 px-1 mx-1">
                      {doc.document_type}
                    </span>
                  )}
                  {fact.documents.length > 1 && index < fact.documents.length - 1 ? <>, </> : <></>}

                  <Tooltip
                    opacity={1}
                    id={`fact-document-tooltip-${fact.event_id}-${doc.doc_id}`}
                    style={TooltipStyles}
                  />
                </span>
              );
            })}
          </div>
        </div>

        <div className="flex justify-between gap-1">
          <div className={`w-5/6 pl-2 text-gray-900 pt-1 ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            {open ? (
              <TextInput
                editable={true}
                entryId={fact.event_id}
                entryFieldKey={'action_described'}
                entryFieldValue={factText}
                endpointToUpdate={`${APIBaseChronos}/client/case/fact/${fact.event_id}`}
                updateState={updateFact}
                location="Fact"
              />
            ) : factText && factText.length > 160 ? (
              factText.slice(0, 160) + '...'
            ) : (
              factText
            )}
          </div>
          {compressedVersion && !hideRelevanceIndicator ? <RelevanceIndicator importance={fact.important} /> : <></>}
          <p
            className={`absolute top-2 text-blue-600 ${
              compressedVersion ? 'text-xs font-normal ' : 'text-sm font-bold '
            } ${referencePosition}`}
          >
            {reference}
          </p>
        </div>
      </div>
    </div>
  );
};

const FactDropdown = ({
  fact,
  userId,
  reference,
  hideRelevanceIndicator,
  compressedVersion = false,
  setIsDateDirty,
  updateFactData,
}: FactDropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleUpdateDate = (newDate: string) => {
    if (updateFactData) {
      updateFactData(fact.event_id, { date_of_subject_text: newDate });
    }
    setIsDateDirty && setIsDateDirty(true);
  };

  const handleUpdateFact = (newFact: string) => {
    if (updateFactData) {
      updateFactData(fact.event_id, { action_described: newFact });
    }
  };

  const handleUpdateSignificance = (newSignificance: string) => {
    if (updateFactData) {
      updateFactData(fact.event_id, { relevance_reason: newSignificance });
    }
  };

  const handleUpdateRelevance = (newRelevance: string) => {
    if (updateFactData) {
      updateFactData(fact.event_id, { important: newRelevance });
    }
  };

  return (
    <Accordion
      title={
        <FactTitle
          fact={fact}
          factText={fact.action_described}
          updateFact={handleUpdateFact}
          dateText={fact.date_of_subject_text}
          updateDate={handleUpdateDate}
          open={open}
          reference={reference}
          compressedVersion={compressedVersion}
          setIsDateDirty={setIsDateDirty}
          hideRelevanceIndicator={hideRelevanceIndicator}
        />
      }
      content={
        <FactDetail
          fact={fact}
          significanceText={fact.relevance_reason}
          updateSignificance={handleUpdateSignificance}
          relevanceText={fact.important}
          updateRelevance={handleUpdateRelevance}
          userId={userId}
          compressedVersion={compressedVersion}
        />
      }
      className="p-0 relative"
      outerOpen={setOpen}
    />
  );
};

export default FactDropdown;
