import React from 'react';

import Button from 'components/atoms/Button';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { marked } from 'marked';
import { useSearchParams } from 'react-router-dom';

import { Message, KimMode } from '../../../types';

interface AnswerContentProps {
  scrollToRow: (id: string, type: string) => void;
  messages: Message[];
  flowType: KimMode | undefined;
}

const formatTable = (
  answer: string,
  references: { [key: number]: { type: string; event_id?: string; chunk_id?: string } },
  scrollToRow: AnswerContentProps['scrollToRow'],
) => {
  const parsed = marked.parse(answer);

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.type === 'text') {
        const text = domNode.data;

        const referenceMatch = text.match(/\[(\d+(?:,\s*\d+)*)\]/g);
        if (referenceMatch) {
          const nums = referenceMatch.map((num: string) => parseInt(num.replace(/[[\]]/g, '').trim(), 10));
          return (
            <span className="inline-flex flex-wrap items-center">
              {nums.map((num: number, i: number) => {
                const ref = references[num];
                const id = ref?.chunk_id || ref?.event_id || '';
                return (
                  <span
                    key={`${num}-${i}`}
                    className={`cursor-pointer ${ref?.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
                    onClick={() => scrollToRow(id, ref?.type || '')}
                  >
                    {i === 0 ? '[' : ''}
                    {num}
                    {i === nums.length - 1 ? ']' : ','}
                  </span>
                );
              })}
            </span>
          );
        }
      }
    },
  };

  return typeof parsed === 'string' ? parse(parsed, options) : '';
};

const formatAnswer = (
  answer: string,
  references: { [key: number]: { type: string; event_id?: string; chunk_id?: string } },
  scrollToRow: AnswerContentProps['scrollToRow'],
) => {
  if (!answer) return;

  // Check if in markdown table format
  const tableRegex = /\|[-:\s|]*\n\|/;
  if (tableRegex.test(answer)) {
    return formatTable(answer, references, scrollToRow);
  }

  const parts = answer.split(/(\[(?:\d+(?:,\s*\d+)*)\]|\*\*.*?\*\*|\n)/);

  return parts.map((part, index) => {
    const referenceMatch = part.match(/^\[(\d+(?:,\s*\d+)*)\]$/);
    const boldMatch = part.match(/^\*\*(.*?)\*\*$/);

    if (referenceMatch) {
      const nums = referenceMatch[1].split(',').map((num) => parseInt(num.trim(), 10));
      return (
        <React.Fragment key={`ref-${index}`}>
          {nums.map((num, i) => {
            const ref = references[num];

            if (!ref) {
              return (
                <span key={`${index}-${num}`}>
                  {i === 0 ? '[' : ''}
                  {num}
                  {i === nums.length - 1 ? ']' : ','}
                </span>
              );
            }

            const id = ref?.chunk_id || ref?.event_id || '';
            return (
              <span
                key={`${index}-${num}`}
                className={`cursor-pointer ${ref.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
                onClick={() => scrollToRow(id, ref.type)}
              >
                {i === 0 ? '[' : ''}
                {num}
                {i === nums.length - 1 ? ']' : ','}
              </span>
            );
          })}
        </React.Fragment>
      );
    } else if (boldMatch) {
      return <strong key={`bold-${index}`}>{boldMatch[1]}</strong>;
    } else if (part === '\n') {
      return <br key={`br-${index}`} />;
    }
    return part;
  });
};

const AnswerContent = ({ messages, scrollToRow, flowType }: AnswerContentProps) => {
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const onNewSearchClick = () => {
    setSearchParams((prev) => {
      prev.delete('threadId');
      return prev;
    });
  };

  const getContentFromFlowType = (flowType: KimMode | undefined, trafficLight: string) => {
    if (trafficLight === 'red') {
      return (
        <div className="text-sm text-gray-800">
          {formatAnswer(messages?.[1]?.message_content, messages?.[1]?.references, scrollToRow)}
        </div>
      );
    }

    if (flowType === 'task_flow')
      return (
        <div className="flex flex-col items-center my-4">
          <div className="w-full max-w-[800px] min-h-[800px] px-12 py-10 bg-white border-2 rounded-lg shadow-lg overflow-x-auto relative before:content-['DRAFT'] before:absolute before:top-64 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:text-gray-200 before:text-[120px] before:font-bold before:rotate-[-45deg] before:pointer-events-none before:select-none before:tracking-[.2em]">
            <div className="text-sm font-serif text-gray-800 leading-relaxed text-justify relative">
              {formatAnswer(messages?.[1]?.message_content, messages?.[1]?.references, scrollToRow)}
            </div>
          </div>
        </div>
      );
    return (
      <div className="text-sm text-gray-800">
        {formatAnswer(messages?.[1]?.message_content, messages?.[1]?.references, scrollToRow)}
      </div>
    );
  };

  const hasNoContent = !messages || !messages[1] || !messages[1].message_content;

  return (
    <div className="px-4 py-3 border-2 rounded overflow-x-auto">
      {hasNoContent ? (
        <div>
          <p className="text-sm mb-3">Unable to process answer, press the button below to start a new search</p>
          <Button text="New search" type="brand" rounded="lg" onClick={onNewSearchClick} />
        </div>
      ) : (
        getContentFromFlowType(flowType, messages?.[1]?.traffic_light)
      )}
    </div>
  );
};

export default AnswerContent;
