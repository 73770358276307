import React, { useEffect, useState } from 'react';

import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import Drawer from 'components/molecules/Drawer';
import InfoModal from 'components/molecules/Modals/Custom';
import { useSearchParams } from 'react-router-dom';
import { ChronosFact, DocumentChunk as Chunk } from 'types';

import AnswerContent from './components/AnswerContent';
import AnswerHeader from './components/AnswerHeader';
import AnswerSidebar from './components/AnswerSidebar';
import ConfidenceTooltip from './components/ConfidenceTooltip';
import SupportingEvidence from './components/SupportingEvidence';
import { getConfidenceLevel, getTitleFromFlowType } from './helpers';
import SkeletonOrComponent from '../../../../../components/molecules/SkeletonOrComponent';
import ChunkDocViewer from '../../DocViewer/ChunkDocViewer';
import DocViewer from '../../DocViewer/FactDocViewer';
import FollowUpQuestions from '../Question/components/FollowUpQuestions';
import { Artifact, Message, KimMode, ThreadObject, ExecuteKimSearch } from '../types';

interface AnswerProps {
  threadId: string;
  caseId: string;
  messages: Message[] | undefined;
  loading?: boolean;
  executeSearch: ExecuteKimSearch;
  threads: ThreadObject[];
  createNewThread: () => void;
  goToThread: (threadId: string) => void;
  isLoadingThreads: boolean;
}

const Answer: React.FC<AnswerProps> = ({
  threadId,
  caseId,
  loading,
  messages,
  executeSearch,
  threads,
  createNewThread,
  goToThread,
  isLoadingThreads,
}) => {
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [updatedFactsData, setUpdatedFactsData] = useState<{ [key: string]: ChronosFact }>({});
  const [artifact, setArtifact] = useState<Artifact | undefined>();
  const [flowType, setFlowType] = useState<KimMode | undefined>();
  const [showAllChunks, setShowAllChunks] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showConfidenceTooltip, setShowConfidenceTooltip] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedChunk, setSelectedChunk] = useState<Chunk | null>(null);
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isChunkViewerOpen, setIsChunkViewerOpen] = useState(false);

  const updateFactData = (eventId: string, data: Partial<ChronosFact>) => {
    setUpdatedFactsData((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], ...data },
    }));
  };
  const docId = searchParams.get('docId');
  const docPageNumber = parseInt(searchParams.get('docPageNumber') || '0', 10);
  const eventId = searchParams.get('eventId');
  const chunkId = searchParams.get('chunkId');

  useEffect(() => {
    if (docId && eventId) {
      trackCustomEvent('Open Kim Doc Viewer');
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId, eventId]);
  useEffect(() => {
    if (docId && chunkId) {
      trackCustomEvent('Open Kim Chunk Viewer');
      setIsChunkViewerOpen(true);
    } else {
      setIsChunkViewerOpen(false);
    }
  }, [docId, chunkId]);

  // Effects
  useEffect(() => {
    if (!loading && messages) {
      // Slight delay to make sure content is rendered
      setTimeout(() => setAnimate(true), 50);
    } else {
      setAnimate(false);
    }
  }, [loading, messages]);

  // If there are no facts but there are chunks, show the chunks
  useEffect(() => {
    if (!messages || !messages[1] || !messages[1].facts || !messages[1].chunks) return;
    if (messages[1].facts.length === 0 && messages[1].chunks.length > 0) {
      setShowAllChunks(true);
    }
  }, [messages]);

  useEffect(() => {
    if (messages && messages[1] && messages[1].facts) {
      const modifiedFacts = messages[1].facts.map((fact: ChronosFact) => ({
        ...fact,
        ...updatedFactsData[fact.event_id],
      }));
      setFacts(modifiedFacts);

      // Set artifact and flow_type if they exist
      setArtifact(undefined);

      // TEMPORARY: Map backend flow types to frontend flow types
      if ((messages[1].flow_type as string) === 'response_flow') setFlowType('task_flow');
      else setFlowType(messages[1].flow_type);
      if (messages[1].artifacts && messages[1].artifacts.length > 0) {
        setArtifact(messages[1].artifacts[0]);
      }
    }
  }, [messages, updatedFactsData]);

  const scrollToRow = (id: string, type: string) => {
    trackCustomEvent('Kim Reference Clicked');
    if (type === 'chunk') {
      setShowAllChunks(true);
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    } else {
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    }
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('relative', 'z-30', 'animate-pulse-border-green');
      setTimeout(() => {
        element.classList.remove('animate-pulse-border-green', 'z-30', 'relative');
      }, 2000);
    }
  };

  const confidenceLevel = getConfidenceLevel(messages?.[1]?.traffic_light);

  return (
    <div className="h-full overflow-y-scroll flex">
      <AnswerSidebar
        messages={messages}
        threads={threads}
        createNewThread={createNewThread}
        goToThread={goToThread}
        isLoadingThreads={isLoadingThreads}
      />

      <div className="h-full overflow-y-scroll py-4 px-4 w-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faLightbulb} className="text-brandSecondary pb-1" />
            <h2 className="text-base font-semibold text-gray-800">
              {getTitleFromFlowType(flowType)}
              {flowType === 'task_flow' && (
                <span className="text-sm text-gray-600 italic"> (please review draft before use)</span>
              )}
            </h2>
          </div>

          <AnswerHeader
            confidenceLevel={confidenceLevel}
            messages={messages}
            setShowConfidenceTooltip={setShowConfidenceTooltip}
            caseId={caseId}
            threadId={threadId}
            artifact={artifact}
          />
        </div>
        <SkeletonOrComponent
          isLoading={loading || !messages}
          component={<AnswerContent scrollToRow={scrollToRow} messages={messages!} flowType={flowType} />}
        />
        {flowType === 'search_flow' ? (
          <></>
        ) : (
          <>
            <SupportingEvidence
              loading={loading}
              animate={animate}
              messages={messages}
              facts={facts}
              showAllChunks={showAllChunks}
              setShowAllChunks={setShowAllChunks}
              confidenceLevel={confidenceLevel}
              updateFactData={updateFactData}
              setSelectedChunk={setSelectedChunk}
            />
            <FollowUpQuestions
              followUpQuestions={messages?.[1]?.follow_up_questions}
              animate={animate}
              loading={loading}
              executeSearch={executeSearch}
            />
          </>
        )}
        <Drawer
          children={
            <DocViewer docId={docId || ''} caseId={caseId || ''} eventId={eventId || ''} pageNumber={docPageNumber} />
          }
          hideCloseButton={true}
          isOpen={isDocViewerOpen}
          onClose={() => {
            // Remove docId from URL
            setSearchParams((prev) => {
              prev.delete('docId');
              prev.delete('eventId');
              prev.delete('docPageNumber');
              return prev;
            });
          }}
        />
      </div>
      <Drawer
        children={
          <ChunkDocViewer
            docId={selectedChunk?.document_id || ''}
            coordinates={selectedChunk?.coordinate_details || []}
          />
        }
        hideCloseButton={true}
        isOpen={isChunkViewerOpen}
        onClose={() => {
          setSearchParams((prev) => {
            prev.delete('docId');
            prev.delete('chunkId');
            return prev;
          });
        }}
      />
      <InfoModal
        content={<ConfidenceTooltip confidenceLevel={confidenceLevel} />}
        isOpen={showConfidenceTooltip}
        handleClose={() => setShowConfidenceTooltip(false)}
      />
    </div>
  );
};

export default React.memo(Answer);
