import { useState } from 'react';

import Button from 'components/atoms/Button';
import moment from 'moment';
import { ChronosItem } from 'types';

interface ExpandedTextState {
  [key: string]: boolean;
}

interface CaseCardProps {
  item: ChronosItem;
  onClick: (itemId: string, status: string) => void;
  onDelete: (itemId: string) => void;
}

const CaseCard = ({ item, onClick, onDelete }: CaseCardProps) => {
  const [expandedText, setExpandedText] = useState<ExpandedTextState>({});

  const toggleTextExpansion = (itemId: string) => {
    setExpandedText((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  return (
    <div className="bg-white w-full rounded-lg px-8 pt-8 flex items-start justify-start flex-col">
      {item.dispute_status && (
        <div className="flex items-start justify-start">
          <div className="font-normal text-xs flex items-center text-gray-500 bg-brandTertiary bg-opacity-20 h-5 rounded mr-3 px-2">
            {item.dispute_status}
          </div>
        </div>
      )}
      {item.case_name && (
        <div
          className="text-xl not-italic font-semibold mt-3"
          onClick={() => onClick(item.case_id, item.pipeline_status)}
          style={{ color: 'var(--black-100, #1C1C1C)' }}
        >
          {item.case_name}
        </div>
      )}
      {item.case_legal_issues && (
        <div
          className="text-xl not-italic font-semibold"
          onClick={() => onClick(item.case_id, item.pipeline_status)}
          style={{ color: 'rgba(28, 28, 28, 0.60)' }}
        >
          {item.case_parties}
        </div>
      )}
      <div className="overflow-hidden text-green-700 text-sm not-italic font-semibold leading-5 right-0 flex justify-between items-center mt-2">
        Date: {`${moment(item.date_created).format('MMMM DD, YYYY')}`}
      </div>
      {item.case_legal_issues && (
        <button
          className={`overflow-hidden text-left text-ellipsis text-sm not-italic font-normal leading-4 mt-3 ${
            expandedText[item.case_id] ? '' : 'line-clamp-3'
          }`}
          style={{ color: 'var(--black-40, rgba(0, 0, 0, 0.40))' }}
          onClick={() => toggleTextExpansion(item.case_id)}
        >
          {item.case_legal_issues}
        </button>
      )}
      <div className="flex justify-between gap-4 mt-4">
        <Button
          rounded="lg"
          size="small"
          text="View"
          type="primary"
          onClick={() => onClick(item.case_id, item.pipeline_status)}
        />
        <Button rounded="lg" size="xs" text="Delete Case" type="delete" onClick={() => onDelete(item.case_id)} />
      </div>
      {item.editor_email && (
        <div className="mb-6 text-gray-800 px-2 py-1 rounded text-xs italic font-normal leading-4 flex items-start bg-yellow-200 bg-opacity-20 mt-3">
          {`Last updated by ${item.editor_email} on ${moment(item.max_date).format('DD/MM/YYYY')}`}
        </div>
      )}
    </div>
  );
};

export default CaseCard;
