import { createContext, FC, ReactNode, useContext } from 'react';

import { useAuthInfo } from '@propelauth/react';
import { trackUser } from 'analytics/Mixpanel';
import useFetchUserData from 'hooks/useFetchUserData';
import { User } from 'types';

interface UserContextType {
  user: User | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const authInfo = useAuthInfo();
  const accessToken = authInfo?.accessToken;
  const { user } = useFetchUserData(accessToken || '');

  trackUser(user);

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};

export { useUserContext, UserContext, UserProvider };
