import { useState } from 'react';

import FileUploadInput from 'components/molecules/FileUploadInput';
import { acceptedDocTypes, FIFTY_KB } from 'components/molecules/FileUploadInput/constants';
import { ParameterValue, PlanStage } from 'types';

import { DraftType, ExecuteKimSearch, KimMode, ThreadObject } from '../types';
import ModeSelector from './components/ModeSelector';
import { PlanDrawer, PlanPage } from './components/Plan';
import QuestionInput from './components/QuestionInput';
import QuestionSidebar from './components/QuestionSidebar';

interface QuestionProps {
  planStage: Record<string, string>;
  plan: PlanStage[];
  processedQuestion: string;
  planOpen: boolean;
  setPlanOpen: (v: boolean) => void;
  loadingPlan: boolean;
  planEditable: boolean;
  loading: boolean;
  updatePlan: (plan: PlanStage[], step: number, parameterName: string, newValue: ParameterValue) => void;
  executePlan: (plan: PlanStage[]) => void;
  executeSearch: ExecuteKimSearch;
  setQuestionValue: (v: string) => void;
  questionValue: string;
  docs: { doc_id: string; file_name: string }[];
  taggedDocuments: { doc_id: string; file_name: string }[];
  setTaggedDocuments: (v: { doc_id: string; file_name: string }[]) => void;
  mode: KimMode;
  setMode: (v: KimMode) => void;
  setTaskFiles: (files: File[]) => void;
  messages: string[];
  resetState: () => void;
  threads: ThreadObject[];
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
  isLoadingThreads: boolean;
  draftType: DraftType | null;
  setDraftType: (draftType: DraftType | null) => void;
}

const getTitleFromMode = (mode: KimMode) => {
  switch (mode) {
    case 'question_flow':
      return 'Ask a question...';
    case 'search_flow':
      return 'Extract information...';
    case 'task_flow':
      return 'Draft me a…';

    default:
      const _unreachable: never = mode;
      return _unreachable;
  }
};

const Question = ({
  planStage,
  plan,
  planOpen,
  loadingPlan,
  planEditable,
  updatePlan,
  loading,
  messages,
  processedQuestion,
  resetState,
  setPlanOpen,
  executePlan,
  executeSearch,
  setQuestionValue,
  questionValue,
  docs,
  taggedDocuments,
  setTaggedDocuments,
  mode,
  setMode,
  threads,
  createNewThread,
  goToThread,
  isLoadingThreads,
  setTaskFiles,
  draftType,
  setDraftType,
}: QuestionProps) => {
  const [placeholder, setPlaceholder] = useState('Tell me about X...');
  const DEV_SHOW_FILE_INPUT = false;

  return (
    <div className="flex w-full h-full relative">
      <PlanDrawer
        planStage={planStage}
        plan={plan}
        planOpen={planOpen}
        setPlanOpen={setPlanOpen}
        loadingPlan={loadingPlan}
        executePlan={executePlan}
        executing={loading}
        // Setting editable to false atm as Plan is not editable yet
        editable={planEditable}
        updatePlan={updatePlan}
        resetState={resetState}
      />
      {planOpen ? (
        <PlanPage questionValue={processedQuestion} loading={loading} messages={messages} />
      ) : (
        <div className="w-full h-full flex items-center">
          <QuestionSidebar
            taggedDocuments={taggedDocuments}
            threads={threads}
            createNewThread={createNewThread}
            goToThread={goToThread}
            isLoadingThreads={isLoadingThreads}
          />

          <div className="w-full flex justify-center">
            <div className="flex flex-col items-center justify-center w-4/5">
              <div className="text-2xl font-bold text-gray-600 mb-8">{getTitleFromMode(mode)}</div>
              <QuestionInput
                onExecuteCall={executeSearch}
                onChange={(v: string) => setQuestionValue(v)}
                value={questionValue}
                docs={docs}
                placeholder={placeholder}
                setTaggedDocuments={setTaggedDocuments}
                draftType={draftType}
                setDraftType={setDraftType}
                mode={mode}
              />
              {DEV_SHOW_FILE_INPUT && (
                <div className="w-full">
                  <FileUploadInput
                    isUploading={false}
                    onDrop={setTaskFiles}
                    acceptedTypes={acceptedDocTypes}
                    tooltipText="Supported file types: DocX, Doc, PDF. Max file size is 150MB."
                    maxFiles={1}
                    maxSize={FIFTY_KB}
                    showPreview
                  />
                </div>
              )}
              <ModeSelector mode={mode} setMode={setMode} setPlaceholder={setPlaceholder} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Question;
