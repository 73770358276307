import React, { useEffect, useRef, useState } from 'react';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import useGetFetchConfig from 'api/useGetFetchConfig';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

interface DateInputProps {
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  updateDate: (v: string) => void;
  setIsDateDirty?: (v: boolean) => void;
  callback?: () => void;
  closePopup?: () => void;
  location?: string;
}

const DateInput: React.FC<DateInputProps> = ({
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  setIsDateDirty,
  callback,
  updateDate,
  closePopup,
  location = '',
}) => {
  const [text, setText] = useState<string>(entryFieldValue);
  const [originalText, setOriginalText] = useState<string>(entryFieldValue);
  const [date, setDate] = useState<Date | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const { getFetchConfig } = useGetFetchConfig();

  const mutation = useMutation(
    async (newValue: string) => {
      updateDate(newValue);
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: newValue } });
      const response = await fetch(endpointToUpdate, fetchConfig);
      if (!response.ok) {
        throw new Error('Failed to update');
      }
      return response.json();
    },
    {
      onSuccess: () => {
        if (setIsDateDirty) setIsDateDirty(true);
        if (callback) callback();
      },
      onError: (error: any) => {
        console.error('Fetch Error: ', error);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error updating the fields. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      },
    },
  );

  // Function to handle text change
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  // Function to handle date change
  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      // Format the date as "dd MMM yyyy"
      const formattedDate = newDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
      setText(formattedDate);
      setDate(newDate);
      if (formattedDate !== originalText) {
        trackCustomEvent(`Field updated ${entryFieldKey} - ${location}`);
        mutation.mutate(formattedDate);
        setOriginalText(formattedDate);
      }
    }
  };

  const handleSubmit = () => {
    if (text !== originalText) {
      trackCustomEvent(`Field updated ${entryFieldKey} - ${location}`);
      mutation.mutate(text);
      setOriginalText(text);
      if (closePopup) closePopup();
    }
  };

  // Function to handle pressing Enter in the text field
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  // Handle click outside to exit editing mode
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        handleSubmit();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [originalText, mutation, text]);

  // Custom input component for DatePicker
  const CustomInput = ({ onClick }: { onClick: () => void }) => (
    <div>
      <FontAwesomeIcon icon={faCalendarDays} onClick={onClick} className="text-gray-700 cursor-pointer w-4 h-4" />
    </div>
  );

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className="flex items-center gap-2 w-full" ref={inputRef} onClick={handleClick}>
      <div className="flex items-center gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4 border border-x-0 border-t-0 pb-2">
            <div className="w-4">
              <DatePicker
                dateFormat="MMMM d, yyyy h:mm aa"
                selected={date}
                onChange={handleDateChange}
                customInput={<CustomInput onClick={() => {}} />}
              />
            </div>
            <input
              className="p-1 rounded border"
              type="text"
              value={text}
              onChange={handleTextChange}
              onKeyDown={handleKeyPress}
              placeholder="Date..."
              autoFocus
            />
          </div>
          <p className="text-xs font-normal text-gray-500 pr-2 py-1">
            Type a verbatim date or date range into the text field, or select a specific date by clicking the calendar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DateInput;
