import { DocumentChunk as Chunk } from 'types';

const DocumentChunk = ({ chunk, handleSelectChunk }: { chunk: Chunk; handleSelectChunk: (chunk: Chunk) => void }) => {
  return (
    <div className="border-2 p-2 rounded" key={chunk.chunk_id}>
      <div className="flex justify-between">
        <div className="font-semibold mt-1 mb-1">Source Text:</div>

        <div>
          <div className="flex gap-2">
            <div
              className="text-blue-500 text-ellipsis overflow-hidden cursor-pointer hover:text-blue-700"
              onClick={() => handleSelectChunk(chunk)}
            >
              {chunk.file_name.length > 90 ? chunk.file_name.slice(0, 90) + '...' : chunk.file_name}
            </div>
            <span className="px-2 rounded-sm bg-brandTertiary bg-opacity-30 text-gray-600">{chunk.document_type}</span>
          </div>
        </div>
      </div>

      <p>{chunk.chunk_text}</p>
    </div>
  );
};

export default DocumentChunk;
