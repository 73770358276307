import moment from 'moment';

interface ViewCellProps {
  date: Date;
  format?: string;
}

const DateCell = ({ date }: ViewCellProps) => {
  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5 w-56">
      {date && (
        <div className="flex flex-row items-center w-full justify-start ml-auto">
          {moment.tz(date, 'Europe/London').format('Do MMMM HH:mm A')}
        </div>
      )}
    </div>
  );
};

export default DateCell;
