import { useState, useRef, useEffect } from 'react';

import { ReactComponent as CalendarEdit } from 'assets/calendar-edit.svg';
import { CSSTransition } from 'react-transition-group';

import DateInput from './DateInput';

interface CalendarEditPopupProps {
  dateText: string;
  entryFieldKey: string;
  updateDate: (v: string) => void;
  endpointToUpdate: string;
  compressedVersion?: boolean;
  setIsDateDirty?: (v: boolean) => void;
  location?: string;
  callback?: () => void;
}

const CalendarEditPopup = ({
  dateText,
  entryFieldKey,
  updateDate,
  endpointToUpdate,
  setIsDateDirty,
  compressedVersion = false,
  location = '',
  callback,
}: CalendarEditPopupProps) => {
  // State
  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState(500);

  // Effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        iconRef.current &&
        !iconRef.current.contains(event.target as Node)
      ) {
        setPopupOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    // This is a bit hacky, find better way
    if (popupOpen && iconRef.current && compressedVersion) {
      const icon = iconRef.current;
      const rect = icon.getBoundingClientRect();
      const yPosition = rect.top + window.scrollY;
      setPosition(yPosition);
    }
  }, [popupOpen, compressedVersion]);

  // Handlers
  const togglePopup = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (iconRef.current) {
      setPopupOpen(!popupOpen);
    }
  };

  return (
    <div className="relative">
      <div ref={iconRef}>
        <CalendarEdit
          className={`hover:cursor-pointer hover:text-blue-500 hover:bg-blue-100 hover:border-blue-300 ${
            compressedVersion ? 'h-6 w-6' : 'h-7 w-7'
          } text-gray-800 bg-gray-100 border border-gray-300 shadow-lg rounded-full p-1`}
          onClick={togglePopup}
        />
      </div>
      {popupOpen && (
        <CSSTransition in={popupOpen} timeout={300} classNames="popup" unmountOnExit nodeRef={popupRef}>
          <div
            ref={popupRef}
            className={`z-20 absolute animate-popIn w-72 shadow-xl bg-white px-6 py-4 rounded border ${
              position > 360 ? 'bottom-8' : 'top-6'
            } `}
          >
            <DateInput
              updateDate={updateDate}
              entryFieldKey={entryFieldKey}
              entryFieldValue={dateText}
              endpointToUpdate={endpointToUpdate}
              setIsDateDirty={setIsDateDirty}
              closePopup={() => setPopupOpen(false)}
              location={location}
              callback={callback ? callback : () => {}}
            />
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export default CalendarEditPopup;
