import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';
import { PlanStage } from 'types';

import { DraftType } from '../types';

interface ProcessPlanProps {
  threadId: string;
  caseId: string;
  question: string;
  docIds: string[];
  mode: string;
  plan: PlanStage[];
  draftType: DraftType | null;
}

const useProcessPlan = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const createMessage = async ({ threadId, question, caseId, docIds, mode, plan, draftType }: ProcessPlanProps) => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        question,
        docIds,
        mode,
        parameters: plan,
        threadId,
        draftType,
      },
    });

    const response = await fetch(`${APIBaseChronos}/client/case/assistant/plan/${caseId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error creating message');
    }
    return response.json();
  };

  const mutation = useMutation(
    async ({ threadId, question, caseId, docIds, mode, plan, draftType }: ProcessPlanProps) => {
      return createMessage({ threadId, question, caseId, docIds, mode, plan, draftType });
    },
  );
   
  return mutation;
};

export default useProcessPlan;
