import { useEffect, useState, memo, useMemo, useCallback } from 'react';

import { faBars, faCog, faExclamationTriangle, faFilter, faStar, faTimeline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackPageView } from 'analytics/Mixpanel';
import CaseCreation from 'assets/guide/case-creator.png';
import ChronologyPage from 'assets/guide/chronology-page.png';
import DocumentPage from 'assets/guide/documents-page.png';
import FactInformation from 'assets/guide/fact.png';
import FactPage from 'assets/guide/facts-page.png';
import HomePage from 'assets/guide/home-page.png';
import KeyFacts from 'assets/guide/key-facts.png';
import KimPage from 'assets/guide/kim-page.png';
import MatterCreation from 'assets/guide/matter-screen.png';
import Modal from 'components/molecules/Modals/Custom';
import { useNavigate } from 'react-router-dom';

interface SidebarItemProps {
  text: string;
  isSubItem?: boolean;
  id: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ text, isSubItem = false, id }) => {
  const handleClick = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <p
      className={`${isSubItem ? 'pl-4 relative flex gap-1' : ''} hover:text-gray-600 cursor-pointer`}
      onClick={handleClick}
    >
      {isSubItem && <span className="absolute left-0 top-[1px] text-gray-500">&#x2514;</span>}
      <span className={isSubItem ? 'pl-1' : ''}>{text}</span>
    </p>
  );
};

const Sidebar = memo(() => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set a small timeout to ensure the initial render is complete
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      className={`sticky top-1 w-56 p-4 pt-10 pr-8 transition-transform duration-300 ease-out ${
        isVisible ? 'animate-slide-in' : 'translate-x-full'
      }`}
    >
      <div className="flex flex-col border rounded-lg p-4 shadow-lg">
        <div className="mb-1 text-gray-600">
          <FontAwesomeIcon icon={faBars} className="mr-2" />
          On this page
        </div>
        <div className="flex flex-col">
          <SidebarItem text="Overview" id="overview" />
          <SidebarItem text="Matters" id="matters" />
          <SidebarItem text="Cases" id="cases" />
          <SidebarItem text="Home" isSubItem id="home" />
          <SidebarItem text="Facts" isSubItem id="facts" />
          <SidebarItem text="Documents" isSubItem id="documents" />
          <SidebarItem text="Chronologies" isSubItem id="chronologies" />
          <SidebarItem text="Kim" isSubItem id="kim" />
        </div>
      </div>
    </div>
  );
});

const Guide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedAltText, setSelectedAltText] = useState('');
  const [imageStyle, setImageStyle] = useState<{ width: string; height: string }>({ width: 'auto', height: 'auto' });

  const navigate = useNavigate();
  const memoizedSidebar = useMemo(() => <Sidebar />, []);

  useEffect(() => {
    trackPageView('Guide');
  }, []);

  const preloadImage = useCallback((imageSrc: string) => {
    return new Promise<void>((resolve) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        let width = Math.min(Math.max(600, img.naturalWidth), window.innerWidth * 0.8);
        let height = width / aspectRatio;
        if (height > window.innerHeight * 0.8) {
          height = Math.round(window.innerHeight * 0.8);
          width = Math.round(height * aspectRatio);
        }

        setImageStyle({
          width: `${width}px`,
          height: `${height}px`,
        });
        resolve();
      };
      img.src = imageSrc;
    });
  }, []);

  const handleImageClick = useCallback(
    async (imageSrc: string, altText: string) => {
      await preloadImage(imageSrc);
      setSelectedImage(imageSrc);
      setSelectedAltText(altText);
      setIsModalOpen(true);
    },
    [preloadImage],
  );

  return (
    <div className="flex flex-col items-start justify-start h-screen overflow-auto pb-10">
      <Modal
        content={
          <div className="flex justify-center items-center" style={imageStyle}>
            <img src={selectedImage} alt={selectedAltText} className="object-contain" />
          </div>
        }
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        maxWidth={`${imageStyle.width}`}
      />
      <div className="w-full flex items-center border-b-2 bg-gray-100">
        <h1 className="text-3xl not-italic font-bold px-8 pt-4 pb-2 text-gray-800 ">User Guide</h1>
      </div>
      <div className="flex">
        <div className="flex flex-col pl-8 text-gray-700 w-5/6 pr-24">
          <div>
            <h2 id="overview" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Overview
            </h2>
            <div>
              <p className="py-2">
                <b>
                  Wexler helps litigators in private practice and in house to create factually accurate and
                  comprehensive chronologies efficiently and effectively, using generative AI.
                </b>
              </p>
              <p className="py-2">
                The problem we solve is the difficulty of establishing facts in a dispute or investigation. We present
                the facts and their dates (the ‘story’ of the case), in a chronology, analyse why these facts are
                relevant to the dispute and deduplicate facts where they are repeated across documents. It is designed
                to save time reviewing documents, ensure that no detail gets missed and help to establish the facts in
                any dispute or investigation.
              </p>

              <div className="w-full border-b pt-2 mb-2"></div>

              <p className="pt-2">The product is designed as a workflow tool in which you can:</p>

              <ul className="list-disc pl-4">
                <li>
                  Extract and review facts (guided by our intelligent AI which helps to identify the most important
                  facts).
                </li>
                <li>Build chronologies from your facts.</li>
                <li>See high level summaries of the overall case and its key issues.</li>
                <li>Ask follow up questions about the case through our conversational interface, Kim.</li>
              </ul>

              <p className="pt-4 pb-2">
                The result is a source of hard facts for your case which saves written off time, improves recovery rates
                and case outcomes, and enhances team collaboration. Outputs can be shared internally, with clients, or
                with outside counsel, and the product lives and grows alongside the case, as you add more documents or
                update the case details.
              </p>

              <p className="py-2 text-gray-500 text-sm">
                We recommend that Wexler be used for ‘hot’ or ‘key’ documents rather than triaging every document in the
                case, due to the costs involved.
              </p>

              <p className="mt-4 font-bold border py-1 px-2 rounded-lg bg-gray-100">
                We work closely with our users to develop the product based on their needs. We can respond quickly to
                bugs or issues, should they arise and we are always on hand to help. If you have feedback or queries,
                please contact us directly at{' '}
                <a className="text-blue-500 font-bold" href="mailto:support@wexler.ai">
                  support@wexler.ai
                </a>
                .
              </p>
            </div>
          </div>

          <div>
            <h2 id="matters" className="text-xl font-semibold pt-8 text-gray-600 border-b-2 border-dashed">
              Get Started
            </h2>
            <div className="flex py-2 gap-8">
              <div className="flex-1">
                To get started you first need to create a <b>matter</b>.
              </div>
            </div>
          </div>

          <div>
            <h2 id="matters" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Matters
            </h2>
            <div className="flex py-2 gap-8">
              <div className="flex-1">
                <p className="py-2">
                  <b>Matters</b> are collections of cases and refer to the security provision which gives users access
                  to cases, meaning users can only access cases within a matter if they are a member of it.
                </p>

                <p className="py-2">
                  There are three security levels: <b>Super Admin</b>, <b>Admin</b> and <b>Member</b>.
                </p>

                <ul className="list-disc pl-5 py-2">
                  <li>
                    <b>Super Admins </b>have access to every matter in an organisation, and should only be used with
                    express internal permissions.
                  </li>
                  <li>
                    <b>Admins</b> have the ability to create new matters, but cannot see other matters unless they are
                    added to them.
                  </li>
                  <li>
                    <b>Members</b> cannot create matters, and can only access matters if they are added to them.
                  </li>
                </ul>
                <p className="py-2 text-gray-600 text-xs">
                  To assign Super Admin and Admin roles please contact the Wexler team.
                </p>
              </div>
              <div className="w-80 flex-shrink-0">
                <img
                  src={MatterCreation}
                  onClick={() => handleImageClick(MatterCreation, 'Matter Creation page')}
                  alt="Matter Creation page"
                  className="border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 id="cases" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Cases
            </h2>
            <div className="flex pt-2 gap-8">
              <div className="flex-1">
                <p className="py-2">
                  Within a matter you can then create a <b>case</b> to start extracting facts from your documents.
                </p>
                <p className="py-2">
                  In the case creation page you will need to enter some metadata about your case. The most important
                  piece of information here is the <b>case details</b>.
                </p>
              </div>
              <div className="w-96 flex-shrink-0">
                <img
                  src={CaseCreation}
                  onClick={() => handleImageClick(CaseCreation, 'Case Creation page')}
                  alt="Case Creation page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                />
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-800 border-b">Case Details</h3>
              <p className="py-2">
                In order for Wexler's AI algorithm to learn and extract key information from your documents, it's
                essential to give an overview of central issues and entities in the case before document extraction
                begins.
              </p>

              <p className="py-2">
                The most important piece of information to fill out here is the <b>case issues</b>, this lists the
                issues of the case, which determines which facts are deemed to be 'key' and 'relevant' and provides the
                basis for the 'themes' in the 'Home' view.
              </p>
              <p className="py-2">
                It should list out the factual issues in prose or in bullet points. If you wish to focus on specific
                issues in the case, it can be useful to add only one issue, which in turn means fewer facts are deemed
                'relevant'. If you wish to analyse a broader range of issues, you can draft a list.
              </p>

              <p className="py-2">
                Also to fill out here is the <b>type of case</b> (e.g. Arbitration, Employment Dispute, Public Inquiry,
                Commercial Litigation) and the <b>case entities</b> (e.g. key parties/actors in the case).
              </p>

              <p className="py-2">
                Optionally, to give Wexler's AI more context, you can fill out any <b>additional context</b> you think
                is important as well as give an <b>estimated timeframe</b> for the case documents.
              </p>

              <p className="py-2 font-semibold text-sm">
                All fields in the case details can be written in plain english. No 'prompting' required!
              </p>
            </div>

            <h3 className="text-lg font-semibold pt-4 text-gray-800 border-b">File Upload</h3>
            <p className="py-2">After you have filled in the case metadata, upload your documents.</p>
            <p className="py-2">
              Our file upload supports EML, MHT, DocX, Doc, PPTx, RTF, PDF, and Spreadsheet files, as well as MSG files
              and many more.
            </p>
            <p className="pb-2 text-gray-500 text-xs">
              Contact the Wexler team to inform us if your file type is not supported.
            </p>

            <p className="py-2">
              A cap of <b>100,000</b> pages currently exists per upload. However, since our pricing model is calculated
              on a per page basis, we recommend fewer pages submitted per upload to get the most out of the platform.
            </p>

            <p className="pb-2 text-gray-500 text-xs">
              Integrations with document management systems and ediscovery/disclosure platforms are in development, but
              not yet live. <b>Relativity</b> will be our first integration where you can import and export documents.
            </p>

            <div className="w-full border-b pt-2 mb-2"></div>

            <p className="mt-4 border p-2 rounded-lg bg-gray-100 text-sm">
              Wexler provides a conservative estimate for the time it takes to extract the facts from the documents. You
              will receive an email when it has finished, but you can expect anywhere from <b>1-8 hours</b> depending on
              the volume and length of documents. For sizeable uploads, we recommend uploading over the weekend or
              overnight. When several users are uploading at once, your documents go into a queue system. You will be
              notified when your case is out of the queue and processing.
            </p>
          </div>

          <div>
            <h2 id="matters" className="text-xl font-semibold pt-8 text-gray-600 border-b-2 border-dashed">
              Analysing Your Case
            </h2>
            <div className="flex py-2 gap-8">
              <div className="flex-1">
                After your documents have finished processing you will be able to see Wexler's analysis of your case.
              </div>
            </div>
          </div>

          <div>
            <h2 id="home" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Home
            </h2>
            <div className="flex py-2 gap-8">
              <div className="flex-1">
                <p className="py-2">
                  The <b>Home</b> page for your case contains important high level information. It creates an overview
                  summary of your case, gives you key statistics from the uploads, highlights relevant documents and
                  actions, identifies important themes and gives you a timeline of key facts. This allows you to view
                  and share a high level overview of your case and gives you a starting point to begin deeper analysis.
                </p>

                <h3 className="text-lg font-semibold pt-4 text-gray-800 border-b">Themes</h3>

                <p className="py-2">
                  The themes displayed on the home page are common trends found across multiple documents, guided by the
                  case details that were entered when the case was created.
                </p>
                <p className="py-2">
                  Each theme has its own summary as well as a list of further subquestions for each and their associated
                  answers.
                </p>

                <p className="py-2 text-gray-500 text-xs">
                  Note that this is not an exhaustive list of themes, but a selection of common issues identified by
                  Wexler's AI.
                </p>

                <h3 className="text-lg font-semibold pt-4 text-gray-800 border-b">Key Facts</h3>
                <p className="py-2">
                  The bottom of the Home page displays the timeline of <b>key facts</b>. These are the facts which the
                  AI deems to be most relevant in the case.{' '}
                </p>
                <p className="py-2 text-gray-500 text-xs">
                  Again, note that this is not necessarily exhaustive, but a selection of some of the most important
                  facts as they relate to the case issues.
                </p>
              </div>
              <div className="w-96 flex-shrink-0 flex flex-col gap-2 justify-between">
                <img
                  src={HomePage}
                  alt="Home page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(HomePage, 'Home page')}
                />
                <img
                  src={KeyFacts}
                  alt="Key facts on home page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(KeyFacts, 'Key facts on home page')}
                />
              </div>
            </div>
          </div>

          <div>
            <h2 id="facts" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Facts
            </h2>
            <div className="flex py-2 gap-8 justify-between">
              <div className="flex-1">
                <p className="py-2">
                  For a deeper analysis of the facts extracted from your documents you can view all of the facts on the{' '}
                  <b>Facts</b> page.
                </p>

                <p className="py-2">
                  Wexler's algorithm extracts all facts included within the submitted documents. The product is
                  deliberately conservative, and extracts every fact, so that users can filter down by different
                  keywords for different elements of a given case to conduct their factual review.
                </p>

                <p className="py-2">
                  Key facts are <span className="text-yellow-500">highlighted</span> and you can click the{' '}
                  <FontAwesomeIcon icon={faStar} className="w-4 h-4" /> to filter the facts page to only show key facts.
                </p>
              </div>
              <div className="w-96 flex-shrink-0">
                <img
                  src={FactPage}
                  alt="Fact page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(FactPage, 'Fact page')}
                />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold pt-4 text-gray-800 border-b">Fact Information</h3>
              <p className="py-2">
                Each fact contains several pieces of information, including description, date, source document(s),
                document type, source text etc... <i>(as shown below)</i>. This is information which has been extracted
                from the documents and you can edit the fact description and fact date here.
              </p>

              <p className="py-2">
                The <b>relevant</b> and <b>significance</b> fields are created by Wexler's AI. For relevance, Wexler's
                AI determines if a fact is highly relevant (yes), not relevant (no), or maybe relevant (these require
                additional review from the user). The significance field describes why a fact is considered relevant/not
                relevant to the core case details. These fields are also editable if you wish to change them.
              </p>

              <p className="py-2">
                These fields help you to identify the most important information and to situate the fact amongst the
                wider case.
              </p>

              <img
                src={FactInformation}
                alt="Fact Information"
                className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                onClick={() => handleImageClick(FactInformation, 'Fact Information')}
              />

              <h2 id="matters" className="text-xl font-semibold pt-8 text-gray-600 border-b-2 border-dashed">
                Using the Facts Page
              </h2>

              <p className="py-2">
                To make the most of the facts page, use filters <FontAwesomeIcon icon={faFilter} className="w-4 h-4" />{' '}
                to narrow down which facts you want to view. Additionally make use of our <b>Hybrid Search</b>, which
                covers both keyword and ‘semantic’ searching. This means that not only will keywords return results but
                also semantically similar phrases.
              </p>

              <p className="py-2">
                Once you have filtered to the facts you want to view, you can select these and click the chronology
                button <FontAwesomeIcon icon={faTimeline} className="w-4 h-4" /> to create a <b>chronology</b> from
                these facts, or add them to an existing chronology.
              </p>

              <p className="py-2 border p-2 rounded-lg bg-gray-100 text-sm mt-2">
                For example, using this workflow you could filter the facts based on a specific topic using our semantic
                search, then toggle to select all the facts returned in this result, and create a chronology from these
                facts to have a timeline of facts related to a specific topic.
              </p>
            </div>
          </div>

          <div>
            <h2 id="documents" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Documents
            </h2>
            <div className="flex py-2 gap-8 justify-between">
              <div className="flex-1">
                <p className="py-2">
                  The documents page allows you to see all of the documents uploaded for your case, read the metadata
                  about these documents, and lets you view the document text itself.
                </p>

                <p className="py-2">
                  If you want to add more documents to your case, you can do this by clicking <b>Add Documents</b> (or
                  going to the case settings <FontAwesomeIcon icon={faCog} className="w-4 h-4" />
                  ).
                </p>

                <p className="py-2">
                  This allows you to add additional documents as well as update your case details, if you want Wexler's
                  AI to focus on different issues.
                </p>

                <p className="py-2 text-gray-500 text-xs">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="w-3 h-3 mr-2" />
                  Warning, updating your case with new case details may be time consuming.
                </p>
              </div>
              <div className="w-96 flex-shrink-0">
                <img
                  src={DocumentPage}
                  alt="Document page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(DocumentPage, 'Document page')}
                />
              </div>
            </div>
          </div>

          <div>
            <h2 id="chronologies" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Chronologies
            </h2>
            <div className="flex py-2 gap-8 justify-between">
              <div className="flex-1">
                <p className="py-2">
                  The Chronologies page allows you to view all of your chronologies. You can click on a chronology to
                  view it.
                </p>

                <p className="py-2">
                  Facts within a chronology can be edited (this will not update the fact outside of the chronology) and
                  facts can be manually added/removed. You can also verify facts within a chronology to mark that you
                  have reviewed and approved it.
                </p>

                <p className="py-2">
                  Once you are happy with your chronology you can export it to Excel and share it with your team.
                </p>
                <p className="py-2 text-gray-500 text-xs">We are building more options to export chronologies.</p>
              </div>
              <div className="w-96 flex-shrink-0">
                <img
                  src={ChronologyPage}
                  alt="Chronology page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(ChronologyPage, 'Chronology page')}
                />
              </div>
            </div>
          </div>

          <div>
            <h2 id="kim" className="text-2xl font-semibold pt-4 text-gray-800 border-b-2">
              Kim
            </h2>
            <div className="flex flex-col py-2 gap-2 justify-between">
              <div className="flex-1">
                <p className="py-2">
                  Kim is Wexler's conversational interface, which allows you to ask open ended questions about your
                  whole case.
                </p>

                <p className="py-2">
                  For common questions on Kim see{' '}
                  <span
                    className="text-blue-500 hover:cursor-pointer"
                    onClick={() => {
                      navigate('/app/chronos/kim-qa');
                    }}
                  >
                    here
                  </span>
                  .
                </p>
              </div>
              <div className="w-full flex-shrink-0">
                <img
                  src={KimPage}
                  alt="Kim page"
                  className="w-full border-2 p-2 rounded-lg bg-gray-100 cursor-zoom-in"
                  onClick={() => handleImageClick(KimPage, 'Kim page')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-1/6">{memoizedSidebar}</div>
      </div>
    </div>
  );
};

export default Guide;
