import { useState } from 'react';

import useGetFetchConfig from 'api/useGetFetchConfig';
import Swal from 'sweetalert2';
import { KEY_VALUE_TYPE, MyOptionType } from 'types';

interface SelectInputCellProps {
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  options: KEY_VALUE_TYPE;
  updateRelevance: (v: string) => void;
  callback?: () => void;
}
const DropdownInput = ({
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  options,
  updateRelevance,
  callback,
}: SelectInputCellProps) => {
  const [currentFieldValue, setCurrentFieldValue] = useState<MyOptionType>(options[entryFieldValue]);
  const { getFetchConfig } = useGetFetchConfig();

  const handleChangeFieldValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value;
    setCurrentFieldValue(options[val]);
    updateRelevance(val);

    const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: val } });
    return fetch(endpointToUpdate, fetchConfig)
      .then((res) => {
        callback && callback();
        return res.json();
      })
      .catch((err) => {
        console.error('Fetch Error: ', err);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error on updating the fields. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <select
      className="rounded hover:cursor-pointer border"
      onChange={handleChangeFieldValue}
      value={currentFieldValue?.value}
    >
      {Object.values(options).map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DropdownInput;
