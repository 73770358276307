import React, { useEffect, useRef, useState } from 'react';

import { faArrowLeft, faArrowRight, faFile, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import LabelledTextInput from 'components/molecules/LabelledTextInput';
import FileUploaderComponent from 'components/organisms/DocumentAdder';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { MyOptionType } from 'types';

import StatusIndictor from '../StatusIndicator';

const CaseCreator: React.FC = () => {
  // State
  // Case Metadata
  const [caseName, setCaseName] = useState<string>('');
  const [caseType, setCaseType] = useState<string>('');
  const [caseIssues, setCaseIssues] = useState<string>('');
  const [caseParties, setCaseParties] = useState<string>('');
  const [caseTimePeriod, setCaseTimePeriod] = useState<string>('');
  const [caseContext, setCaseContext] = useState<string>('');
  const [disputeStatus, setDisputeStatus] = useState<MyOptionType | null>();

  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [totalActiveDocs, setTotalActiveDocs] = useState(0);

  const { caseId } = useParams();
  const navigate = useNavigate();

  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  // Define refs for each textarea
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTypeRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaIssuesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaPartiesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTimePeriodRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaContextRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '56px';
      if (ref.current.scrollHeight > 56) {
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }
  };

  // Queries
  // Get case
  const {
    isFetching: isLoadingCase,
    data: responseCase,
    refetch: refetchGetCase,
  } = useQuery(
    ['getCase', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  // Update case
  const { data: responseUpdateCase, refetch: refetchUpdateCase } = useQuery(
    ['updateCase', caseId],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus: disputeStatus?.value,
          caseKeyTimePeriod: caseTimePeriod,
          caseKeyContext: caseContext,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  // Pre-save case
  const { refetch: refetchPreSaveCase } = useQuery(
    ['preSave', caseId],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus: disputeStatus?.value,
          caseKeyTimePeriod: caseTimePeriod,
          caseKeyContext: caseContext,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/presave/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  // Effects
  useEffect(() => {
    if (caseId) {
      refetchGetCase();
    }
    // eslint-disable-next-line
  }, [caseId]);

  useEffect(() => {
    if (responseUpdateCase?.updateDocObject?.case_id) {
      navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`);
    }
    // eslint-disable-next-line
  }, [responseUpdateCase]);

  const handleChangeCaseName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseName(e.target.value);
    autoResize(textAreaNameRef);
  };

  const handleChangeCaseType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseType(e.target.value);
    autoResize(textAreaTypeRef);
  };
  const handleChangeCaseIssues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseIssues(e.target.value);
    autoResize(textAreaIssuesRef);
  };

  const handleChangeCaseContext = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseContext(e.target.value);
    autoResize(textAreaContextRef);
  };

  const handleChangeCaseTimePeriod = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseTimePeriod(e.target.value);
    autoResize(textAreaTimePeriodRef);
  };

  const handleChangeCaseParties = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseParties(e.target.value);
    autoResize(textAreaPartiesRef);
  };
  const handleUpdateCase = () => {
    refetchUpdateCase();
  };

  useEffect(() => {
    if (responseCase?.caseObject) {
      setCaseName(responseCase?.caseObject?.case_name);
      setCaseIssues(responseCase?.caseObject?.case_legal_issues);
      setCaseParties(responseCase?.caseObject?.case_parties);
      setCaseTimePeriod(responseCase?.caseObject?.case_key_time_period);
      setCaseContext(responseCase?.caseObject?.case_key_context);
      setDisputeStatus(
        DISPUTE_STATUS_OPTIONS[responseCase?.caseObject?.dispute_status as keyof typeof DISPUTE_STATUS_OPTIONS],
      );
      setCaseType(responseCase?.caseObject?.case_type);
    }
  }, [responseCase]);

  const handleClickBack = () => {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to stop creating the case?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'rgb(37 99 235)',
    }).then((result) => {
      if (result.isConfirmed) {
        refetchPreSaveCase();
        navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const handleChangeDisputeStatus = (val: MyOptionType) => {
    setDisputeStatus(val);
  };

  useEffect(() => {
    // Function to save accessToken to local storage
    const saveAccessToken = () => {
      localStorage.setItem('accessToken', accessToken || '');
    };
    // Save immediately when component mounts
    saveAccessToken();
    // Start an interval to save accessToken every second
    const intervalId = setInterval(saveAccessToken, 1000);
    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [accessToken]);

  return (
    <div className="flex flex-row relative gap-6 overflow-auto w-full ">
      <div className="flex flex-col pl-8 w-full items-start justify-start">
        {isLoadingCase ? (
          <div className="w-full h-full flex flex-col items-center justify-start  pt-14">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            <div>
              <div className="flex items-center mt-5">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mr-4 w-5 h-5 cursor-pointer"
                  onClick={() => handleClickBack()}
                />
                <div className=" text-xl not-italic font-bold w-full">Create New Case</div>
              </div>
              <div className="absolute right-6 top-4">
                <StatusIndictor />
              </div>
            </div>

            <div className="flex w-full text-gray-800 pb-4">
              <div className="w-1/2 mt-4">
                <div className="text-lg border-b w-fit pr-20 mb-4">
                  <FontAwesomeIcon icon={faPenToSquare} className="mr-2 text-gray-600 w-4 h-4" />
                  Case Details
                </div>

                <div className="flex flex-col gap-4 mt-4">
                  <LabelledTextInput
                    id="caseNameInput"
                    value={caseName}
                    onChange={handleChangeCaseName}
                    label="Name of case"
                  />
                  <div>
                    <LabelledTextInput
                      id="caseIssuesInput"
                      value={caseIssues}
                      onChange={handleChangeCaseIssues}
                      label="Case issues"
                    />
                    <div className="text-gray-500 text-xs mt-1 px-1">
                      Outline the key issues in the case as you understand them, either in list format or prose.
                    </div>
                  </div>

                  <div className="border-b mt-2 border-gray-300"></div>

                  <div>
                    <LabelledTextInput
                      id="caseTypeInput"
                      value={caseType}
                      onChange={handleChangeCaseType}
                      label="Type of case"
                      small
                    />
                    <div className="text-gray-500 text-xs mt-1 px-1">
                      Case type could be Arbitration, Employment Dispute, Public Inquiry, Commercial Litigation, etc...
                    </div>
                  </div>

                  <div>
                    <LabelledTextInput
                      id="casePartiesInput"
                      value={caseParties}
                      onChange={handleChangeCaseParties}
                      label="Key actors (persons or entities)"
                      small
                    />
                    <div className="text-gray-500 text-xs mt-1 px-1">
                      Identifying the key actors helps Wexler's algorithms to focus on which entities are most important
                      in the case.
                    </div>
                  </div>

                  <div className="border-b mt-2 mb-2 border-gray-300"></div>

                  <div>
                    <LabelledTextInput
                      id="caseContextInput"
                      value={caseContext}
                      onChange={handleChangeCaseContext}
                      label="Additional context (optional)"
                      small
                    />
                    <div className="text-gray-500 text-xs mt-1 px-1">
                      Any other contextual information Wexler should be aware of. E.g. "There is a parallel litigation
                      happening in a different country with similar issues" or "The claimant is a public figure".
                    </div>
                  </div>
                  <div>
                    <LabelledTextInput
                      id="caseTimePeriodInput"
                      value={caseTimePeriod}
                      onChange={handleChangeCaseTimePeriod}
                      label="Case time period (optional)"
                      small
                    />
                    <div className="text-gray-500 text-xs mt-1 px-1">
                      The estimated time period of the documents, e.g. "1960 to 1990" or "Early 2000s" (don't worry
                      about the formatting of the dates).
                    </div>
                  </div>

                  <div className="relative flex items-center text-sm">
                    <Select
                      options={Object.values(DISPUTE_STATUS_OPTIONS)}
                      className={`outline-none rounded-md w-full`}
                      styles={reactSelectStylesCaseCreator}
                      onChange={handleChangeDisputeStatus}
                      value={disputeStatus}
                      placeholder={'Dispute status (optional)'}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 mt-4 px-6">
                <div className=" text-lg flex">
                  <div className="text-lg border-b w-fit pr-20">
                    <FontAwesomeIcon icon={faFile} className="mr-2 text-gray-600 w-4 h-4" />
                    Files {totalActiveDocs ? `(${totalActiveDocs})` : ''}{' '}
                  </div>
                </div>
                <FileUploaderComponent
                  setUploadDisabled={setUploadDisabled}
                  setTotalActiveDocs={setTotalActiveDocs}
                  caseId={caseId || ''}
                  isCaseCreator={true}
                />

                {!isLoadingCase && (
                  <button
                    className="cursor-pointer flex flex-row justify-center items-center mt-5 bg-brandSecondary rounded-lg hover:bg-brandSecondary-hover disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-2"
                    onClick={handleUpdateCase}
                    disabled={uploadDisabled || !caseName || !caseType || !caseIssues || !caseParties}
                  >
                    Start Processing
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CaseCreator;
