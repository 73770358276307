import { faClock, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CaseState } from '../..';
import RunsView from '../../RunsView';

interface PipelineCompleteOverviewProps {
  totalRuns: number;
  caseState: CaseState;
  handleClickStatusView: () => void;
}

const PipelineCompleteOverview = ({ caseState, totalRuns, handleClickStatusView }: PipelineCompleteOverviewProps) => {
  const BORDER_STYLES = !!totalRuns ? 'border-yellow-600' : 'border-green-500';
  const TEXT_STYLES = !!totalRuns ? 'text-yellow-600' : 'text-green-500';

  return (
    <div
      className={
        'text-sm shadow-sm relative px-5 rounded-lg py-2 bg-white border border-solid items-center justify-center text-black flex flex-row cursor-pointer ' +
        BORDER_STYLES
      }
      onClick={handleClickStatusView}
    >
      <FontAwesomeIcon icon={faClock} className={TEXT_STYLES} />
      <div className={'mx-4 font-semibold ' + TEXT_STYLES}>
        {!!totalRuns ? `${totalRuns} ${totalRuns === 1 ? 'Run' : 'Runs'} In Progress` : 'All Runs Complete'}
      </div>
      <FontAwesomeIcon
        className={'transition-transform duration-500 ' + TEXT_STYLES}
        style={{ transform: caseState.runStatusIsOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        icon={faChevronDown}
      />
      <div
        className={`absolute transition-all duration-500 right-0 top-12 z-10 overflow-scroll ${
          caseState.runStatusIsOpen ? 'max-h-80' : 'max-h-0'
        }`}
      >
        <RunsView runs={caseState.runs} />
      </div>
    </div>
  );
};

export default PipelineCompleteOverview;
