import React from 'react';

interface StatisticItemProps {
  title: string;
  value: string | number | null;
  isFetching: boolean;
}

const formatValue = (value: string | number | null) => {
  if (value === null) {
    return null;
  }
  // Convert value to a string if it is a number
  const stringValue = value.toString();

  // Format the number with commas
  const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Determine the text size based on the length of the value
  let textSizeClass = 'text-5xl';
  if (formattedValue.length > 10) {
    textSizeClass = 'text-xl';
  } else if (formattedValue.length > 6) {
    textSizeClass = 'text-2xl';
  }

  // Return the formatted value wrapped in a span with the appropriate class
  return <span className={`${textSizeClass}`}>{formattedValue}</span>;
};

const StatisticItem: React.FC<StatisticItemProps> = ({ title, value, isFetching }) => (
  <div className="w-1/4">
    <div className="bg-white rounded-lg p-8">
      <div className="text-black opacity-40 text-sm mb-5">{title}</div>
      <div className="text-black font-semibold">
        {isFetching || value === null ? (
          <div className="h-12 w-36 bg-gray-100 rounded-2xl animate-pulse" />
        ) : (
          formatValue(value)
        )}
      </div>
    </div>
  </div>
);

export default StatisticItem;
