import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { ChronosChronologyEvent } from 'types';

import DocViewer from '../../DocViewer/FactDocViewer';
import ChronologyDropdown from '../components/ChronologyDropdown';

interface ChronologyTimelineProps {
  chronology: ChronosChronologyEvent[];
  currentUserId: string;
  handleOpenDeleteModal: (lineId: string) => void;
}

const ChronologyTimeline: React.FC<ChronologyTimelineProps> = ({
  chronology,
  currentUserId,
  handleOpenDeleteModal,
}) => {
  // State
  const [searchParams] = useSearchParams();
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);

  const caseId = searchParams.get('caseId');
  const docId = searchParams.get('docId');
  const eventId = searchParams.get('eventId');
  const docPageNumber = parseInt(searchParams.get('docPageNumber') || '0', 10);

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  return (
    <div className="flex overflow-hidden h-full">
      <div className="relative flex">
        {/* Vertical line - moved outside scrollable area */}
        <div className="absolute left-4 top-0 h-full border-l-2 border-gray-300"></div>

        <div
          className={`transition-all duration-300 overflow-y-auto flex relative flex-col gap-4 pr-1 ${
            isDocViewerOpen ? 'w-7/12' : 'w-full'
          }`}
        >
          {chronology.map((event: ChronosChronologyEvent, index: number) => (
            <div key={index} className="">
              <div className="flex items-center">
                {/* Marker */}
                <div className="w-4 h-4 rounded-full absolute left-[9px] bg-white border-2 border-brandSecondary"></div>
                {/* Fact content */}
                <div
                  key={event.event_id}
                  id={event.event_id}
                  className="ml-10 border-2 border-opacity-40 rounded w-full relative"
                >
                  <ChronologyDropdown
                    event={event}
                    currentUserId={currentUserId}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`transition-all duration-300 ease-in-out ${
          isDocViewerOpen ? 'w-5/12 opacity-100 ml-2' : 'w-0 opacity-0'
        } border-2 rounded bg-white overflow-hidden`}
      >
        <div
          className={`w-full h-full transition-transform duration-300 ease-in-out ${
            isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          {docId && (
            <DocViewer docId={docId} caseId={caseId || ''} eventId={eventId || ''} pageNumber={docPageNumber} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChronologyTimeline;
