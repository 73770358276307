import { useEffect, useRef } from 'react';

import {
  faCircle,
  faCircleNotch,
  faList,
  faRocket,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KimLoadingAnimation from 'assets/animations/KimLoading.json';
import LottieAnimation from 'components/atoms/Lottie';
import { AnimatePresence, motion } from 'framer-motion';
import { ParameterValue, PlanStage } from 'types';

import PlanStep from './components/PlanStep';

interface PlanDrawerProps {
  planOpen: boolean;
  setPlanOpen: (open: boolean) => void;
  loadingPlan: boolean;
  executePlan: (plan: PlanStage[]) => void;
  updatePlan: (plan: PlanStage[], step: number, parameterName: string, newValue: ParameterValue) => void;
  planStage: Record<string, string>;
  plan: PlanStage[];
  executing: boolean;
  editable: boolean;
  resetState: () => void;
}

const PlanDrawer = ({
  planOpen,
  setPlanOpen,
  loadingPlan,
  updatePlan,
  executePlan,
  planStage,
  plan,
  executing,
  editable,
  resetState,
}: PlanDrawerProps) => {
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <AnimatePresence>
      {planOpen && (
        <motion.div
          className="h-full border-r py-2 w-2/5 max-w-[400px] rounded-br-sm rounded-tl-md shadow-sm"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="text-lg font-medium border-b w-full">
                <p className="px-4 pb-1 flex items-center">
                  <FontAwesomeIcon icon={faList} className="pr-2 w-3 h-3" />
                  Plan
                </p>
              </div>

              {loadingPlan ? (
                <div className="flex flex-col gap-4 justify-center items-center h-[400px] border-2 rounded-lg mt-10 mx-4">
                  <p className="text-gray-500 text-sm">Building plan...</p>
                  <FontAwesomeIcon icon={faCircleNotch} className="w-3 h-3 fa-spin" />
                </div>
              ) : (
                <div>
                  <p className="px-4 pt-3 pb-1 text-gray-800 text-sm">Kim will execute the following plan:</p>
                  <div
                    className={`p-4 text-sm text-gray-700 max-h-[600px] overflow-y-auto border-2 mx-4 my-1 rounded-lg ${
                      executing ? 'pointer-events-none' : ''
                    }`}
                  >
                    <motion.div
                      className="flex flex-col relative"
                      variants={container}
                      initial="hidden"
                      animate="visible"
                    >
                      {/* Continuous vertical line */}
                      <div className="absolute left-[0.7rem] top-4 bottom-2 w-0 border-l-2 border-gray-300 z-0"></div>
                      {plan.map((step, index) => (
                        <PlanStep
                          key={index}
                          step={step}
                          index={index}
                          executing={executing}
                          planStage={planStage}
                          updatePlan={(parameterName: string, newValue: ParameterValue) =>
                            updatePlan(plan, index, parameterName, newValue)
                          }
                        />
                      ))}
                    </motion.div>
                  </div>
                </div>
              )}
            </div>
            {editable ? (
              <div className="px-4 pb-4 pt-1">
                <div className="text-xs text-gray-500 py-4">
                  You can alter any highlighted <span className="bg-blue-100 px-1 rounded text-gray-600">options</span>{' '}
                  of a plan.
                </div>
                <div className="flex flex-row gap-2 justify-between">
                  <button
                    onClick={() => resetState()}
                    className="bg-red-50 border border-red-300 text-gray-700 rounded px-2 py-1 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={executing || loadingPlan}
                  >
                    Cancel
                    <FontAwesomeIcon icon={faXmark} className="pl-2" />
                  </button>
                  <button
                    onClick={() => executePlan(plan)}
                    className="bg-green-50 border border-green-300 text-gray-700 rounded px-2 py-1 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={executing || loadingPlan}
                  >
                    Execute Plan
                    <FontAwesomeIcon icon={faWandMagicSparkles} className="pl-2" />
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface PlanPageProps {
  questionValue: string;
  loading: boolean;
  messages: string[];
}

const PlanPage = ({ questionValue, loading, messages }: PlanPageProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const processMessages = (messages: string[]): React.ReactNode => {
    if (!messages || messages.length === 0) return '';

    return messages.map((message, messageIndex) => {
      const paragraphs = message.split('<br>');

      return (
        <motion.div
          key={messageIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex justify-start items-baseline border-b pb-1 border-gray-200 border-dashed"
        >
          <FontAwesomeIcon icon={faCircle} className="w-2 h-2 animate-pulse text-brandSecondary mr-2" />
          <div className="flex flex-col">
            {paragraphs.map((paragraph, index) => {
              // Trim leading/trailing commas and spaces
              const trimmedParagraph = paragraph.trim().replace(/^,+|,+$/g, '');
              // Replace numbers with bold numbers using JSX
              const parts = trimmedParagraph.split(/(\d+)/);
              return (
                <p key={index} className="mb-2 last:mb-0">
                  {parts.map((part, partIndex) => (isNaN(Number(part)) ? part : <b key={partIndex}>{part}</b>))}
                </p>
              );
            })}
          </div>
        </motion.div>
      );
    });
  };

  return (
    <div className="flex-1 mt-4 ml-7 mr-6 pl-1 min-w-0">
      <h1
        className={`mb-2 px-4 py-2 bg-gray-50 rounded-r-3xl rounded-tl-xl border w-full relative text-sm max-h-[200px] overflow-y-auto ${
          questionValue.length > 250 && 'text-xs'
        }`}
      >
        <pre className="font-lota break-words whitespace-pre-line">
          {questionValue.charAt(0).toUpperCase() + questionValue.slice(1)}
        </pre>
      </h1>
      {loading ? (
        <div>
          <div className="flex justify-center items-center h-28">
            <LottieAnimation animationData={KimLoadingAnimation} loop={true} autoplay={true} className="h-28" />
          </div>
          <div className="mt-2">
            <div className="text-gray-800 font-medium mb-2">
              <FontAwesomeIcon icon={faRocket} className="w-4 h-4 mr-2" />
              Executing Plan:
            </div>
            <AnimatePresence>
              <motion.div className="text-sm text-gray-800 flex flex-col gap-1 max-h-[300px] border rounded-md p-2 overflow-y-auto">
                {processMessages(messages)}
                <div ref={messagesEndRef} />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-6">
          <div className="h-4 w-full bg-gray-100 rounded-full animate-pulse"></div>
          <div className="h-4 w-full bg-gray-100 rounded-full animate-pulse"></div>
          <div className="h-4 w-full bg-gray-100 rounded-full animate-pulse"></div>
          <div className="h-4 w-full bg-gray-100 rounded-full animate-pulse"></div>
          <div className="h-4 w-full bg-gray-100 rounded-full animate-pulse"></div>
          <div className="h-4 w-3/4 bg-gray-100 rounded-full animate-pulse"></div>
        </div>
      )}
    </div>
  );
};

export { PlanDrawer, PlanPage };
