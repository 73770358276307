import React from 'react';

import ThreadsBar from '../../../ThreadsBar';
import { Message, ThreadObject } from '../../../types';

interface AnswerSidebarProps {
  messages: Message[] | undefined;
  threads: ThreadObject[];
  createNewThread: () => void;
  goToThread: (threadId: string) => void;
  isLoadingThreads: boolean;
}

const ChatBubble = ({ text }: { text: string }) => {
  return (
    <div className="whitespace-pre-wrap w-fit max-h-52 max-w-[90%] overflow-y-scroll py-1 px-3 border rounded-xl bg-gray-50 text-xs">
      {text}
    </div>
  );
};

const AnswerSidebar: React.FC<AnswerSidebarProps> = ({
  messages,
  threads,
  createNewThread,
  goToThread,
  isLoadingThreads,
}) => {
  return (
    <div className="flex flex-col w-56 min-w-[14rem] bg-white border-r rounded-tl-lg">
      <div className="h-1/2 flex flex-col justify-between pt-2 border-b border-dashed pb-2">
        <div className="text-sm font-semibold text-gray-800 pb-1 border-b">
          <p className="px-2">Question</p>
        </div>

        <div className="h-full flex flex-col justify-between pt-4 px-2 overflow-y-scroll">
          <div className="flex flex-col gap-2 items-start">
            <ChatBubble text={messages && messages.length > 0 ? messages[0].message_content : '...'} />
          </div>
        </div>
      </div>

      <div className="h-1/2 bg-gray-50 bg-opacity-50">
        <ThreadsBar
          threads={threads}
          createNewThread={createNewThread}
          goToThread={goToThread}
          isLoadingThreads={isLoadingThreads}
        />
      </div>
    </div>
  );
};

export default AnswerSidebar;
