import { ConfidenceLevelObject, KimMode, MessageTrafficLight } from '../types';

const getConfidenceLevel = (trafficLight?: MessageTrafficLight): ConfidenceLevelObject => {
  switch (trafficLight) {
    case 'green':
      return {
        borderColor: 'border-green-500',
        tooltipColor: 'bg-green-500',
        backgroundColor: 'bg-green-50',
        textColor: 'text-green-500',
        text: 'High',
        tooltip:
          'Kim has high confidence in the answer because it has found supporting facts and document text to support it.',
      };
    case 'amber':
      return {
        borderColor: 'border-yellow-500',
        tooltipColor: 'bg-yellow-500',
        backgroundColor: 'bg-yellow-50',
        textColor: 'text-yellow-500',
        text: 'Medium',
        tooltip:
          'Kim has found some supporting information for this answer, but recommends double checking the output and sources.',
      };
    case 'red':
      return {
        borderColor: 'border-red-500',
        tooltipColor: 'bg-red-500',
        backgroundColor: 'bg-red-50',
        textColor: 'text-red-500',
        text: 'Low',
        tooltip:
          'Kim has low confidence in the answer and does not consider this a satisfactory answer. Some supporting information may be present.',
      };
    default:
      return {
        borderColor: 'border-gray-500',
        tooltipColor: 'bg-gray-500',
        backgroundColor: 'bg-gray-50',
        textColor: 'text-gray-500',
        text: 'Unknown',
        tooltip: 'There was a problem generating the answer, so Kim is unable to provide a confidence level.',
      };
  }
};

const getTitleFromFlowType = (flowType: KimMode | undefined) => {
  if (flowType === 'question_flow') return 'Answer';
  if (flowType === 'search_flow') return 'Extraction';
  if (flowType === 'task_flow') return 'Draft';
  return 'Answer';
};

export { getConfidenceLevel, getTitleFromFlowType };
