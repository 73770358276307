import { useState, useEffect, useRef } from 'react';

import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import Button from 'components/atoms/Button';
import TooltipIcon from 'components/atoms/TooltipIcon';
import Accordion from 'components/molecules/Accordion';
import { TooltipStyles } from 'constants/styles';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import CalendarEditPopup from 'screens/Chronos/CaseEditor/CaseSummary/components/KeyFactsTimeline/components/CalendarPopup';
import CommentPopup from 'screens/Chronos/CaseEditor/CaseSummary/components/KeyFactsTimeline/components/Comments';
import { PlainTextContent } from 'screens/Chronos/CaseEditor/CaseSummary/components/KeyFactsTimeline/components/helpers';
import { renderHighlightedTextWithLinks } from 'screens/Chronos/CaseEditor/CaseSummary/components/KeyFactsTimeline/components/helpers';
import TextInput from 'screens/Chronos/CaseEditor/CaseSummary/components/KeyFactsTimeline/components/TextInput';
import { useDocumentNavigation } from 'screens/Chronos/CaseEditor/FactsEditor/utils/navigationUtils';
import { ChronosChronologyEvent, ChronosDocumentCoordinate } from 'types';

import useVerifyChronologyEvent from '../../hooks/useVerifyChronologyEvent';

interface DetailProps {
  fact: ChronosChronologyEvent;
  significanceText: string;
  updateSignificance: (v: string) => void;
  currentUserId: string;
  handleOpenDeleteModal: (lineId: string) => void;
}
interface TitleProps {
  open: boolean;
  fact: ChronosChronologyEvent;
  factText: string;
  updateFact: (v: string) => void;
  dateText: string;
  updateDate: (v: string) => void;
  setIsDateDirty?: (v: boolean) => void;
}

interface UnverifyPopUpProps {
  email: string;
  isOpen: boolean;
  onClose: () => void;
  unVerify: () => void;
}
const UnverifyPopUp: React.FC<UnverifyPopUpProps> = ({ email, isOpen, onClose, unVerify }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleUnVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    trackCustomEvent('Fact unverified');
    onClose();
    unVerify();
  };

  return (
    <div
      ref={popupRef}
      className="absolute left-2 bottom-[-20px] bg-white pt-3 w-52 shadow-md z-10 flex flex-col gap-2 border rounded"
    >
      <p className="text-xs px-4">
        <b>Verified by:</b> {email ? email.slice(0, 18) + (email.length > 18 ? '...' : '') : null}
      </p>
      <div className="border-t bg-gray-50 pb-2">
        <div className="px-4 mt-2 flex justify-end">
          <Button text="Unverify" type="delete" onClick={handleUnVerify} size="xs" rounded="base" />
        </div>
      </div>
    </div>
  );
};

const Detail = ({ fact, significanceText, updateSignificance, currentUserId, handleOpenDeleteModal }: DetailProps) => {
  const uploadDate = new Date(fact.date_uploaded);
  const { goToDocReference } = useDocumentNavigation();

  return (
    <div className="text-xs">
      <div className="border border-dashed border-blue-200 border-x-0 border-t-0 mt-2"></div>

      <div className="flex gap-4 py-4 pr-4 pl-2">
        {/* Significance Section */}
        <div className="w-full">
          <div className="font-semibold mb-1 px-2 flex gap-1 items-center text-gray-800">
            Significance
            <TooltipIcon
              tooltipId={`significance-tooltip-${fact.event_id}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from."
              className="mr-2 w-4 h-4 cursor-pointer"
            />
          </div>
          <div className="text-gray-800 px-2">
            <TextInput
              editable={true}
              entryId={fact.event_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={significanceText}
              endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${fact.line_id}`}
              updateState={updateSignificance}
              location="Chronology"
            />
          </div>
        </div>
      </div>

      <div className="border border-dashed border-x-0 border-t-0 border-blue-200"></div>

      <div className="py-4 pr-4 pl-2 ">
        {/* Documents and Source Text Section */}
        <div className="font-semibold mb-1 px-2 text-gray-800">Documents</div>
        <div>
          {fact.documents.map((document: ChronosDocumentCoordinate) => {
            const pageNumber = document?.coordinate_details?.[0]?.n_page || 1;
            const file = document?.file_name?.split('.')?.filter(Boolean)[0];
            return (
              <div
                key={file}
                className="flex px-2 gap-4 items-baseline justify-between border border-x-0 border-t-0 pb-1 border-dashed"
              >
                <div className="flex items-baseline gap-4">
                  <div
                    key={document.doc_id}
                    className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden "
                    onClick={() => goToDocReference(document.doc_id, fact.event_id, pageNumber)}
                  >
                    {file}
                  </div>
                  <span className="px-2 rounded-sm bg-brandTertiary bg-opacity-30 text-gray-600">
                    {document.document_type}
                  </span>
                  {document.document_date_text ? (
                    <span className=" text-gray-600 text-xs">
                      (<span className="text-xs">{document.document_date_text})</span>
                    </span>
                  ) : null}
                </div>
                <div className="flex items-baseline gap-6">
                  <span className="text-xs text-gray-600 italic">
                    (uploaded: {`${uploadDate.getDate()}/${uploadDate.getMonth() + 1}/${uploadDate.getFullYear()}`})
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div className="font-semibold mt-3 mb-1 px-2 text-gray-800">Source Text</div>
          <div className="text-gray-500 px-2">
            <PlainTextContent html={fact.source_text} maxLength={600} />
          </div>
        </div>
      </div>
      <div className="border-2  border-x-0 border-t-0 border-blue-200"></div>
      {/* Action Buttons Section */}
      <div className="flex justify-between px-4 bg-slate-100 gap-10 rounded-b-lg">
        <div>
          <div className="font-semibold pt-2 pb-1">Comments</div>
          <div>
            {fact.first_comment ? (
              renderHighlightedTextWithLinks(fact.first_comment)
            ) : (
              <span className="text-gray-500 font-light">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup
            thread_id={fact.event_id}
            comments_count={fact.comments_count || 0}
            currentUserId={currentUserId}
          />
          <button
            onClick={() => handleOpenDeleteModal(fact.line_id)}
            className="py-2 px-3 relative h-full text-red-700 border flex items-center gap-2 rounded shadow bg-gray-50 hover:bg-gray-100 hover:cursor-pointer hover:shadow-none"
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const Title = ({ open, fact, dateText, updateDate, setIsDateDirty, factText, updateFact }: TitleProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [verified, setVerified] = useState(fact.verified);
  const [verifiedDate, setVerifiedDate] = useState(fact.verified_date);
  const [verifiedBy, setVerifiedBy] = useState(fact.verified_by_email);

  const { goToDocReference } = useDocumentNavigation();

  const onSuccess = (data: any) => {};
  const onError = (data: any) => {};

  const { updateVerifiedMutation } = useVerifyChronologyEvent({ onSuccess, onError });
  const handleVerifyChronology = (verify: boolean) => {
    setVerified(verify);
    setVerifiedDate(new Date().toISOString());
    setVerifiedBy(fact.verified_by_email || 'you');
    updateVerifiedMutation.mutate({ line_id: fact.line_id, verified: verify });
  };

  return (
    <div className="flex items-center justify-between font-normal w-full">
      <div className="px-2 pl-2 w-full p-[6px]">
        {/* Date Section */}
        <div className="flex justify-between w-full">
          <div className="font-semibold flex gap-2 items-center text-xs">
            <CalendarEditPopup
              dateText={dateText}
              entryFieldKey="fact_date_text"
              updateDate={updateDate}
              compressedVersion={true}
              endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${fact.line_id}`}
              setIsDateDirty={setIsDateDirty}
              location="Chronology"
            />

            {dateText ? dateText : '(Date Unknown)'}
          </div>
          {/* Documents */}
          <div className="text-blue-600 text-xs">
            {fact.documents.map((doc, index) => {
              if (index === 1) return <>...</>;
              if (index > 1) return <></>;
              return (
                <span key={doc.file_name}>
                  <span
                    data-tooltip-id={`fact-document-tooltip-${fact.event_id}-${doc.doc_id}`}
                    data-tooltip-content={doc.file_name}
                    onClick={(e) => {
                      e.stopPropagation();
                      goToDocReference(doc.doc_id, fact.event_id, doc?.coordinate_details?.[0]?.n_page || 1);
                    }}
                  >
                    {doc.file_name ? doc.file_name.slice(0, 20).trim() + '...' : ''}
                  </span>
                  {doc.document_type && (
                    <span className="rounded-sm text-gray-600 bg-brandTertiary bg-opacity-20 px-1 mx-1">
                      {doc.document_type}
                    </span>
                  )}
                  {fact.documents.length > 1 && index < fact.documents.length - 1 ? <>, </> : <></>}

                  <Tooltip
                    opacity={1}
                    id={`fact-document-tooltip-${fact.event_id}-${doc.doc_id}`}
                    style={TooltipStyles}
                  />
                </span>
              );
            })}
          </div>
        </div>

        {/* Fact description */}
        <div className="flex justify-between gap-1">
          <p className="w-5/6 pl-2 text-gray-900 pt-1 text-xs">
            {open ? (
              <TextInput
                editable={true}
                entryId={fact.event_id}
                entryFieldKey={'longer_description'}
                entryFieldValue={factText}
                endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${fact.line_id}`}
                updateState={updateFact}
                location="Chronology"
              />
            ) : factText.length > 160 ? (
              factText.slice(0, 160) + '...'
            ) : (
              factText
            )}
          </p>

          {verified ? (
            <div className="flex gap-2 items-center relative">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTooltip(!showTooltip);
                }}
                className="text-xs border border-green-700 bg-green-50 text-gray-800 px-2 py-1 h-6 rounded flex gap-1 items-center"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-700" />
                Verified
              </button>

              <div className="text-xs text-gray-600">
                {verifiedDate ? moment(verifiedDate).format('MMM DD, YYYY') : null}
              </div>
              <UnverifyPopUp
                email={verifiedBy || ''}
                isOpen={showTooltip}
                onClose={() => setShowTooltip(false)}
                unVerify={() => handleVerifyChronology(false)}
              />
            </div>
          ) : (
            <Button
              text="Verify"
              onClick={(e) => {
                e.stopPropagation();
                trackCustomEvent('Fact verified');
                handleVerifyChronology(true);
              }}
              className="text-xs bg-buttonPrimary hover:bg-buttonPrimary-hover text-white shadow px-3 py-1 h-6 rounded "
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ChronologyDropdown = ({
  event,
  currentUserId,
  handleOpenDeleteModal,
}: {
  event: ChronosChronologyEvent;
  currentUserId: string;
  handleOpenDeleteModal: (lineId: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  // Setup fact state here so edits are reflected immediately on FE
  const [dateText, setDateText] = useState(event.fact_date_text);
  const [factText, setFactText] = useState(event.longer_description);
  const [significanceText, setSignificanceText] = useState(event.relevance_reason);

  return (
    <Accordion
      title={
        <Title
          open={open}
          fact={event}
          dateText={dateText}
          updateDate={setDateText}
          setIsDateDirty={() => {}}
          factText={factText}
          updateFact={setFactText}
        />
      }
      content={
        <Detail
          fact={event}
          significanceText={significanceText}
          updateSignificance={setSignificanceText}
          currentUserId={currentUserId}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      }
      className="p-0 relative"
      outerOpen={setOpen}
    />
  );
};

export default ChronologyDropdown;
