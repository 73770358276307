import React from 'react';

import { ReactComponent as NewThreadIcon } from 'assets/new-thread.svg';

import { ThreadObject } from '../types';

interface ThreadsBarProps {
  threads: ThreadObject[];
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
  isLoadingThreads: boolean;
}
interface ThreadItemProps {
  thread_text: string;
  thread_id: string;
  goToThread: (thread_id: string) => void;
}

const ThreadItem: React.FC<ThreadItemProps> = ({ thread_text, thread_id, goToThread }) => {
  return (
    <div
      className="cursor-pointer py-2 px-2 rounded hover:bg-gray-200 transition-colors duration-150 overflow-x-clip whitespace-nowrap text-ellipsis text-xs"
      onClick={() => thread_id && goToThread(thread_id)}
    >
      {thread_text}
    </div>
  );
};

const ThreadsBar: React.FC<ThreadsBarProps> = ({ threads, createNewThread, goToThread, isLoadingThreads }) => {
  return (
    <>
      <div className="mb-1 h-full">
        <div className="px-4 pt-2 pb-1 border-b font-semibold text-sm flex items-center gap-2 mb-1">
          Previous
          <NewThreadIcon
            onClick={createNewThread}
            className="h-6 w-6 hover:bg-gray-200 hover:cursor-pointer p-1 rounded-full"
          />
        </div>
        <div
          className="flex flex-col px-2 gap-1 overflow-y-scroll overflow-x-clip"
          style={{ height: 'calc(100% - 3rem)' }}
        >
          {isLoadingThreads ? (
            <div className="flex flex-col justify-center overflow-hidden w-52">
              {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                <div key={i} className="h-8 w-52 animate-pulse bg-gray-100 rounded px-2 my-1 "></div>
              ))}
            </div>
          ) : (
            <>
              {threads?.map((threadObject: ThreadObject) => {
                return (
                  <ThreadItem
                    key={threadObject.thread_id}
                    thread_text={threadObject.thread_name}
                    thread_id={threadObject.thread_id}
                    goToThread={goToThread}
                  />
                );
              })}{' '}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ThreadsBar);
