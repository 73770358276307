import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { trackCustomEvent, trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ParameterValue, PlanParameters, PlanStage } from 'types';

import Answer from './Answer';
import useCreateThreadAndMessage from './hooks/useCreateThreadAndMessage';
import useGetMessages from './hooks/useGetMessages';
import useGetThreads from './hooks/useGetThreads';
import useProcessPlan from './hooks/useProcessPlan';
import Question from './Question';
import { DraftType, ExecuteKimSearch, KimMode } from './types';
import useFetchDocIds from '../DocumentsEditor/hooks/useFetchDocIds';

const CaseAssistant = React.memo(() => {
  // State
  const [questionValue, setQuestionValue] = useState('');
  const [draftType, setDraftType] = useState<DraftType | null>(null);
  const [taskFiles, setTaskFiles] = useState<File[]>([]);
  const [mode, setMode] = useState<KimMode>('question_flow');
  const [taggedDocuments, setTaggedDocuments] = useState<{ doc_id: string; file_name: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [processedQuestion, setProcessedQuestion] = useState('');
  const [messages, setMessages] = useState<string[]>([]);

  const [planOpen, setPlanOpen] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [planStage, setPlanStage] = useState<Record<string, string>>({});
  const [plan, setPlan] = useState<PlanStage[]>([]);
  const [planEditable, setPlanEditable] = useState(false);
  // Doing this for now, but we should probs re-think how we deal with completed messages etc
  const [planThreadId, setPlanThreadId] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const caseId = searchParams.get('caseId');
  const threadId = searchParams.get('threadId');

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const { responseThreads, isLoadingThreads, refetchThreads } = useGetThreads(caseId || '');
  const threads = useMemo(() => responseThreads?.threads?.reverse(), [responseThreads]);
  const { isLoadingMessages, responseMessages, refetchMessages } = useGetMessages(threadId || '');
  const { data: responseDocIds } = useFetchDocIds(caseId || '');

  // Mutations
  const { mutate: createThreadAndMessage } = useCreateThreadAndMessage();
  const { mutate: processPlan } = useProcessPlan();

  // Effects
  useEffect(() => {
    refetchThreads();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (threadId) {
      refetchMessages();
    }
    // eslint-disable-next-line
  }, [threadId]);

  // Handlers
  const handleNewThread = useCallback(() => {
    trackCustomEvent('Kim New Thread');
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [navigate, location, searchParams]);

  const goToThread = useCallback(
    (thread_id: string) => {
      // Clear the search params
      searchParams.delete('selectedDocId');

      searchParams.set('threadId', thread_id);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [navigate, location, searchParams],
  );

  const updatePlan = (plan: PlanStage[], step: number, parameterName: string, newValue: ParameterValue) => {
    // Get the plan step
    const planStep = plan[step];

    // Find the parameter
    const parameter = planStep.parameters.find((p) => p.name === parameterName);
    const index = planStep.parameters.findIndex((p) => p.name === parameterName);

    // Set the parameter value to the new value
    if (!parameter) {
      return;
    }
    const updatedParameter = { ...parameter, value: newValue, updated: true };

    // Update the plan
    planStep.parameters[index] = updatedParameter as PlanParameters;
    const newPlan = [...plan];
    newPlan[step] = planStep;

    setPlan(newPlan);
  };

  const errorToast = useCallback(() => {
    toast.error('Error processing your question. Please try again.');
  }, []);

  const resetState = () => {
    setLoading(false);
    setLoadingPlan(false);
    setPlanOpen(false);
    setPlanEditable(false);
    setPlanStage({});
    setPlan([]);
    setProcessedQuestion('');
    setQuestionValue('');
    setPlanThreadId('');
    setTaggedDocuments([]);
    setMessages([]);
    // Clear the search params
  };

  const processQuestion = (question: string) => {
    // Remove the @[...](...)  format and keep only the content inside square brackets, wrapped in quotes
    return question.replace(/@\[(.*?)\]\(.*?\)/g, '"$1"');
  };

  // Question answer handling
  const handleExecutePlanSuccess = (data: any) => {
    if (data.threadId && data.messageId && data.status === 200) {
      // Add retry count state
      let retryCount = 0;
      const maxRetries = 3;

      const createEventSource = () => {
        const eventSource = new EventSourcePolyfill(`${APIBaseChronos}/client/case/assistant/poll/${data.messageId}`, {
          headers: Object.fromEntries(fetchConfigGET.headers.entries()),
          heartbeatTimeout: 360000,
        });

        eventSource.onmessage = (event: any) => {
          const message: { stage: string; type: string; message: string } = JSON.parse(event.data);

          if (message.type === 'error') {
            searchParams.delete('selectedDocId');
            navigate(`${location.pathname}?${searchParams.toString()}`);
            console.error('type 1: explicit error from sqs queue');
            errorToast(); // this one is fine -> explicit error from sqs queue
            resetState();
            eventSource.close();
            return;
          }

          // Start message
          if (message.type === 'task_start') {
            setPlanStage((prevStages) => ({
              ...prevStages,
              [message.stage]: 'in-progress',
            }));
            setMessages((prevMessages) => [...prevMessages, message.message]);
          }

          // Progress message
          if (message.type === 'task_message') {
            setMessages((prevMessages) => [...prevMessages, message.message]);
            return;
          }

          // Complete message
          if (message.type === 'task_complete') {
            setPlanStage((prevStages) => {
              const updatedStages = {
                ...prevStages,
                [message.stage]: 'completed',
              };

              // If all stages are completed, then close the event source
              if (Object.values(updatedStages).every((value) => value === 'completed')) {
                setMessages((prevMessages) => [...prevMessages, 'Completing...']);
                eventSource.close();

                setTimeout(() => {
                  // If a doc id is set, clear it
                  searchParams.delete('selectedDocId');

                  searchParams.set('threadId', data.threadId);
                  navigate(`${location.pathname}?${searchParams.toString()}`);
                  resetState();
                  refetchThreads();
                }, 500);
              }

              return updatedStages;
            });
          }
        };

        eventSource.onerror = (error: any) => {
          eventSource.close();

          // Check if it's a network error and we haven't exceeded max retries
          if (error.error?.message === 'network error' && retryCount < maxRetries) {
            retryCount++;
            console.log(`Retrying connection... Attempt ${retryCount} of ${maxRetries}`);

            // Retry after a delay (exponential backoff)
            setTimeout(() => {
              createEventSource();
            }, Math.min(1000 * Math.pow(2, retryCount), 10000));
          } else {
            // If max retries exceeded or different error, show error toast
            console.error('EventSource error:', error);
            errorToast();
            resetState();
            searchParams.delete('selectedDocId');
            navigate(`${location.pathname}?${searchParams.toString()}`);
          }
        };

        return eventSource;
      };

      createEventSource();
    } else {
      console.error('type 3: error when initial plan is invalid');
      errorToast(); // this one is fine -> happens when the initial plan is invalid
      resetState();
    }
  };

  const handleExecuteSearchSuccess = (data: any) => {
    if (data.parameters && Object.keys(data.parameters).length > 0) {
      setPlan(data.parameters);

      // Go through the plan and set the plan stage to not-started, unless the plan stage has is_complete bool, then set to completed
      const updatedPlanStages = data.parameters.reduce((acc: Record<string, string>, stage: PlanStage) => {
        acc[stage.name] = stage.is_complete ? 'completed' : 'not-started';
        return acc;
      }, {});
      setPlanStage(updatedPlanStages);

      setPlanThreadId(data.threadId);
    } else {
      console.error('type 4: error updating a plan or reading it');
      errorToast(); // this one is fine  -> error updating a plan or reading it
      resetState();
      return;
    }
    setLoadingPlan(false);

    // If the response says no user feedback is needed, then send a request back to start execution
    if (data.planRequiresConfirmation) {
      // If user feedback is needed set the plan to be editable
      setPlanEditable(true);
    } else {
      executePlan({ threadId: data.threadId, currentPlan: data.parameters, question: data.question, draftType: null });
    }
  };

  // Send off the search query and get back the plan
  const executeSearch: ExecuteKimSearch = ({ question: value, draftType }) => {
    if (value.trim() === '') {
      return;
    }
    const question = processQuestion(value);
    setProcessedQuestion(question);
    setLoadingPlan(true);
    setPlanOpen(true);

    // Fetch the plan
    createThreadAndMessage(
      {
        caseId: caseId || '',
        question: question,
        docIds: taggedDocuments.map((doc) => doc.doc_id),
        draftType,
        files: taskFiles,
        mode: mode,
      },
      {
        onSuccess: handleExecuteSearchSuccess,
        onError: (error) => {
          console.error('type 5: error creating thread and message');
          errorToast(); // this one is fine -> error creating thread
          resetState();
        },
      },
    );
  };

  // TODO: this is a hacky solution, best to remove question argument and allow FollowUpQuestions to setQuestionValue
  const executePlan = ({
    threadId,
    currentPlan,
    draftType,
    question,
  }: {
    threadId: string;
    currentPlan: PlanStage[];
    draftType: DraftType | null;
    question?: string;
  }) => {
    trackCustomEvent('Kim Question Asked');

    setLoading(true);
    setMessages(['Starting plan execution...']);

    // Process the question (could use processQuestion here but maybe race condition if state hasn't updated yet)
    const processedQuestion = processQuestion(question || questionValue);

    setQuestionValue('');
    processPlan(
      {
        threadId: threadId,
        caseId: caseId || '',
        question: processedQuestion,
        docIds: taggedDocuments.map((doc) => doc.doc_id),
        mode,
        draftType,
        plan: currentPlan,
      },
      {
        onSuccess: handleExecutePlanSuccess,
        onError: (error) => {
          console.error('Error creating thread and message:', error);
          console.error('type 6: error executing plan');
          errorToast(); // this one is fine -> error executing plan
          resetState();
        },
      },
    );
  };

  // Analytics
  trackPageView('Kim');

  return (
    <div className="w-full flex gap-2 h-[87%]">
      <>
        <div className="flex flex-col w-full border bg-white h-full rounded-md border-opacity-40">
          {threadId ? (
            <Answer
              caseId={caseId || ''}
              threadId={threadId || ''}
              messages={responseMessages?.messages}
              loading={isLoadingMessages}
              executeSearch={executeSearch}
              threads={threads}
              createNewThread={handleNewThread}
              goToThread={goToThread}
              isLoadingThreads={isLoadingThreads}
            />
          ) : (
            <Question
              // Plan
              planStage={planStage}
              planOpen={planOpen}
              setPlanOpen={setPlanOpen}
              plan={plan}
              updatePlan={updatePlan}
              planEditable={planEditable}
              // Loading states
              loadingPlan={loadingPlan}
              loading={loading}
              messages={messages}
              resetState={resetState}
              // Question Answering
              executeSearch={executeSearch}
              executePlan={(plan: PlanStage[]) => executePlan({ threadId: planThreadId, currentPlan: plan, draftType })}
              setQuestionValue={setQuestionValue}
              processedQuestion={processedQuestion}
              questionValue={questionValue}
              draftType={draftType}
              setDraftType={setDraftType}
              // Document @-ing
              docs={responseDocIds?.docs || []}
              taggedDocuments={taggedDocuments}
              setTaggedDocuments={setTaggedDocuments}
              // Question Flow
              mode={mode}
              setMode={setMode}
              // Threads
              threads={threads}
              createNewThread={handleNewThread}
              goToThread={goToThread}
              isLoadingThreads={isLoadingThreads}
              setTaskFiles={setTaskFiles}
            />
          )}
        </div>
      </>
    </div>
  );
});

export default CaseAssistant;
