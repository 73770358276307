import React, { useEffect, useState } from 'react';

import { faAdd, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import Button from 'components/atoms/Button';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosItem } from 'types';

import useGetFetchConfig from '../../../api/useGetFetchConfig';
import CaseCard from '../../../components/molecules/CaseCard';

const CasesExplorer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();
  const searchTextQuery = searchParams.get('searchText');
  const catchwordsQuery = searchParams.get('catchwords');
  const dateFromQuery = searchParams.get('dateFrom');
  const dateToQuery = searchParams.get('dateTo');
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';
  const pageQuery = parseInt(searchParams.get('page') || '1') || 1;
  const advancedFiltersActive = false;
  const [searchText, setSearchText] = useState(searchTextQuery || '');
  const nPages = 1;
  const matterId = searchParams.get('matterId');

  const {
    isFetching: isFetchingCases,
    data: responseData,
    refetch,
  } = useQuery(
    'exploreResult',
    () => {
      const cleanSearchText = searchText?.replace(/\s+/g, ' ').trim() || '';
      return fetch(
        `${APIBaseChronos}/client/case/byMatterId/${matterId}?search=${cleanSearchText}&page=${pageQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        fetchConfigGET,
      )
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingMatter,
    data: responseMatterData,
    refetch: refetchMatterData,
  } = useQuery(
    'matterDataResult',
    () => {
      return fetch(`${APIBaseChronos}/client/case/matter/${matterId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  useEffect(() => {
    if (responseMatterData?.type === 'unauthorized') {
      Swal.fire({
        title: '',
        text: 'Unauthorized. You are being redirected to the matters explorer',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      navigate(`/app/chronos/matters`);
    }
    // eslint-disable-next-line
  }, [responseMatterData]);

  useEffect(() => {
    setSearchText(searchTextQuery || '');
    refetch();
    refetchMatterData();
    // eslint-disable-next-line
  }, [searchTextQuery]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClickCaseItem = (id: string, pipeline_status: string) => {
    if ([PIPELINE_STATUS.draft.value].includes(pipeline_status)) {
      navigate(`/app/chronos/case-creator/${id}`);
    } else {
      navigate(`/app/chronos/case-editor/summary?caseId=${id}`);
    }
  };

  const handleGoToPreviousPage = () => {
    if (pageQuery > 1) {
      if (advancedFiltersActive) {
        navigate(
          `/app/explore?searchText=${searchTextQuery}&page=${
            pageQuery - 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/explore?searchText=${searchText}&page=${pageQuery - 1}`);
      }
    }
  };

  const handleGoToNextPage = () => {
    if (pageQuery < nPages) {
      if (advancedFiltersActive) {
        navigate(
          `/app/chronos/explore?searchText=${searchTextQuery}&page=${
            pageQuery + 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/chronos/explore?searchText=${searchText}&page=${pageQuery + 1}`);
      }
    }
  };

  const goToNewCase = async () => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        matterId: matterId,
      },
    });
    const res = await fetch(`${APIBaseChronos}/client/case`, fetchConfig);
    const resJson = await res.json();
    navigate(`/app/chronos/case-creator/${resJson?.newCaseObject?.case_id}`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  const handleClickDelete = (caseIdToDelete: string) => {
    Swal.fire({
      title: '',
      text: 'Deleting cases is irreversible. Do you want to proceed?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        const fetchConfig = getFetchConfig({ method: 'DELETE' });
        return fetch(`${APIBaseChronos}/client/case/${caseIdToDelete}`, fetchConfig)
          .then((res) => {
            return res.json();
          })
          .then((resJson) => {
            if (resJson.success) {
              Swal.fire({
                title: '',
                text: 'Case deleted successfully',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              refetch();
            } else if (resJson.notCreator) {
              Swal.fire({
                title: 'Not authorized',
                text: 'You are not the creator of this case',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: '',
                text: 'Error deleting case. Try again later',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '',
              text: 'Error deleting case. Try again later',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000,
            });
            console.error(err);
          });
      }
    });
  };

  const handleClickMatters = () => {
    navigate(`/app/chronos/matters`);
  };

  return (
    <div className=" flex flex-col w-full h-screen">
      <div className="flex flex-col md:flex-row items-start justify-start text-white h--full overflow-y-auto">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-row w-full flex-wrap mt-5">
            <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">
              {(isLoadingMatter || isFetchingCases) && (
                <div className="w-full h-full flex items-center justify-center absolute top-0 left-0 right-0 bottom-0">
                  <StageSpinner className="m-auto" size={40} color={'#081D57'} />
                </div>
              )}

              {!isLoadingMatter && !isFetchingCases && (
                <div className="mb-4 w-full pr-10 pl-3 flex flex-col">
                  <div className="text-gray-900 text-2xl not-italic font-semibold mb-4 flex flex-row items-center cursor-pointer">
                    <span className="text-gray-700 hover:text-gray-800" onClick={handleClickMatters}>
                      Matters
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="h-5 mx-4 text-gray-900" />
                    {responseMatterData?.matterDetails?.matter_name}
                  </div>
                  <div className="relative bg-white mb-4 border rounded-lg w-96 flex items-center py-2 px-2 gap-4">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                    <input
                      className="w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
                      onChange={handleChangeSearchInput}
                      onKeyDown={handleKeyDown}
                      value={searchText}
                      placeholder={'Search cases'}
                    />
                  </div>

                  <Button
                    type="brand"
                    rounded="lg"
                    icon={<FontAwesomeIcon icon={faAdd} className="text-white mr-2" />}
                    text="New Case"
                    onClick={goToNewCase}
                  />
                </div>
              )}
              {!isFetchingCases && !isLoadingMatter && !responseData?.items?.length && (
                <div className="text-xl text-gray-900 mb-4 w-full pl-3">No existing cases found</div>
              )}
              <div className="pr-7 w-full flex flex-row flex-wrap">
                {!isFetchingCases &&
                  !isLoadingMatter &&
                  responseData?.items?.length > 0 &&
                  responseData?.items
                    ?.filter((item: ChronosItem) => !item.is_removed)
                    ?.map((item: ChronosItem) => {
                      return (
                        <div key={item.case_id} className="p-3 w-2/4 xl:w-1/3">
                          <CaseCard item={item} onClick={handleClickCaseItem} onDelete={handleClickDelete} />
                        </div>
                      );
                    })}
              </div>
              {nPages > 1 && (
                <div className="w-full flex flex-row items-center justify-center text-black my-10">
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery <= 1}
                    onClick={handleGoToPreviousPage}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <button className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer">
                    {pageQuery}
                  </button>
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery >= nPages}
                    onClick={handleGoToNextPage}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesExplorer;
