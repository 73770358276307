import React, { useState, useCallback, useEffect } from 'react';

import { faCircleNotch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import { reactSelectStylesDownloadColumns, reactSelectStylesDownloadFormat } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import Select, { MultiValue, SingleValue, ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';
import { MyOptionType } from 'types';

const COLUMN_OPTIONS = [
  { label: 'Date (verbatim)', value: 'Date (verbatim)' },
  { label: 'Date (standardised)', value: 'Date (standardised)' },
  { label: 'Fact', value: 'Fact' },
  { label: 'Source Text', value: 'Source Text' },
  { label: 'Source Document', value: 'Source Document' },
  { label: 'Significance', value: 'Significance' },
  { label: 'Event ID', value: 'Event ID' },
  { label: 'Notes', value: 'Notes' },
  { label: 'Notes Date', value: 'Notes Date' },
  { label: 'Notes By', value: 'Notes By' },
];

const FORMAT_OPTIONS = [
  { label: 'Excel', value: 'csv' },
  { label: 'Docx', value: 'docx' },
];

interface ChronologyDownloadModalProps {
  chronologyTitle: string;
  handleDownload: ({ title, columns, format }: { title: string; columns: string[]; format: string }) => void;
  loadingDownload: boolean;
}
const ChronologyDownloadModal = ({
  chronologyTitle,
  handleDownload,
  loadingDownload,
}: ChronologyDownloadModalProps) => {
  const [title, setTitle] = useState(chronologyTitle);
  const [columns, setColumns] = useState<MyOptionType[]>(COLUMN_OPTIONS);
  const [format, setFormat] = useState<MyOptionType[]>([FORMAT_OPTIONS[0]]);
  useEffect(() => {
    setTitle(chronologyTitle);
  }, [chronologyTitle]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const handleChangeSelectedColumns = useCallback(
    (newValue: MultiValue<MyOptionType> | SingleValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setColumns(Array.isArray(newValue) ? newValue : newValue ? [newValue] : []);
    },
    [],
  );

  const handleChangeSelectedFormat = useCallback(
    (newValue: MultiValue<MyOptionType> | SingleValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setFormat(Array.isArray(newValue) ? newValue : newValue ? [newValue] : []);
    },
    [],
  );

  const handleDownloadClick = () => {
    const columnValues = columns.map((column) => column?.value).filter((value) => value !== undefined) as string[];
    const formatValue = format[0]?.value as string;
    handleDownload({ title, columns: columnValues, format: formatValue });
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      {loadingDownload ? (
        <div className="flex flex-col justify-center items-center h-72 gap-8">
          <div className="text-xl">Downloading...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="text-4xl fa-spin text-brandSecondary" />
        </div>
      ) : (
        <div className="px-6 py-4">
          <div className="flex flex-col gap-1 mb-4 ">
            <label className="text-sm font-medium text-gray-600">Title</label>
            <input
              onChange={handleChangeTitle}
              className="border h-10 px-1 w-full focus:outline-none not-italic font-normal text-sm text-black placeholder:text-gray-400 rounded"
              value={title}
              placeholder="Title"
            ></input>
          </div>
          <div className="flex flex-col gap-1 mb-4 ">
            <label className="text-sm font-medium text-gray-600">Columns</label>
            <Select
              options={COLUMN_OPTIONS}
              components={animatedComponents}
              className="outline-none w-full bg-white rounded-md"
              styles={reactSelectStylesDownloadColumns}
              isMulti
              onChange={handleChangeSelectedColumns}
              value={columns}
              placeholder={'Select columns'}
              maxMenuHeight={200}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-600">Format</label>
            <Select
              options={FORMAT_OPTIONS}
              className="outline-none w-full bg-white rounded-md"
              styles={reactSelectStylesDownloadFormat}
              onChange={handleChangeSelectedFormat}
              value={format}
              placeholder={'Select format'}
              maxMenuHeight={200}
            />
          </div>

          <div className="border-t border-gray-200 my-4"></div>

          <Button
            onClick={handleDownloadClick}
            text="Download"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faDownload} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default ChronologyDownloadModal;
