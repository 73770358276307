import React from 'react';

import { ChronosFact } from 'types';

import FactDropdown from './components/FactDropdown';

const KeyFactsTimeline = ({
  factProps,
  userId,
  references,
  updateFactData,
}: {
  factProps: ChronosFact[];
  userId: string;
  references: { [key: string]: number };
  updateFactData: (eventId: string, data: Partial<ChronosFact>) => void;
}) => {
  // State

  return (
    <>
      {factProps ? (
        <div className="relative flex flex-col gap-4">
          {/* Vertical line */}
          <div className="absolute left-4 top-0 h-full border-l-2 border-gray-300"></div>

          {factProps.map((fact: ChronosFact, index: number) => (
            <div key={index} className="">
              <div className="flex items-center">
                {/* Marker */}
                <div className="w-5 h-5 rounded-full absolute left-[7px] bg-white border-2 border-brandSecondary"></div>
                {/* Fact content */}
                <div
                  key={fact.event_id}
                  id={fact.event_id}
                  className="ml-10 border-2 border-opacity-40 rounded bg-gray-50 w-full"
                >
                  <FactDropdown
                    fact={fact}
                    userId={userId}
                    reference={references[fact.event_id]}
                    updateFactData={updateFactData}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default KeyFactsTimeline;
