import { faCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfidenceLevelObject } from '../../../types';

interface ConfidenceChipProps {
  confidenceLevel: ConfidenceLevelObject;
  onClick: () => void;
}

const ConfidenceChip = ({ confidenceLevel, onClick }: ConfidenceChipProps) => {
  return (
    <div
      onClick={onClick}
      className={`hover:cursor-pointer relative ${confidenceLevel.backgroundColor} shadow text-xs rounded px-2 py-1 border ${confidenceLevel.borderColor} flex items-center gap-2 border`}
    >
      <p>
        Confidence: <b>{confidenceLevel.text}</b>
      </p>
      <FontAwesomeIcon icon={faCircle} className={`${confidenceLevel.textColor}`} />
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="text-blue-500 hover:text-blue-800 rounded-full mr-2 text-sm cursor-pointer ml-2 absolute top-[-8px] right-[-16px]"
      />
    </div>
  );
};

export default ConfidenceChip;
