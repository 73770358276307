import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const KimQA = () => {
  return (
    <div className="flex flex-col items-start justify-start h-screen overflow-auto pb-10">
      <div className="w-full flex items-center border-b-2 bg-gray-100">
        <h1 className="text-3xl not-italic font-bold px-8 pt-4 pb-2 text-gray-800 ">Kim Q&A</h1>
      </div>

      <div className="flex-1 px-8 mt-4 text-gray-700">
        {/* Question 1 */}
        <h3 className="text-lg font-semibold border-b">What can Kim do?</h3>
        <p className="py-2">
          Kim can answer questions about facts extracted from a case, where each and every piece of information is
          backed up with a source in the documents themselves. This means that users can, and should, verify each answer
          with reference to the source provided. It will also showcase the full range of sources used to provide each
          answer.
        </p>

        <p className="pb-2 text-gray-500 text-sm">
          Kim only looks at the facts and documents in your case, not the wider internet or other sources.
        </p>

        {/* Question 3 */}
        <h3 className="mt-4 text-lg font-semibold border-b">What do Kim's 'confidence' levels mean?</h3>
        <p className="py-2">
          Wexler values transparency above all else. We have built a system which rates the confidence of answers for
          completeness and accuracy.
        </p>

        <ul className="list-disc ml-5 text-sm">
          <li>
            <FontAwesomeIcon icon={faCircle} className="text-green-500" /> Green means Kim has high confidence in and
            has found sources to back up its answer.
          </li>
          <li>
            <FontAwesomeIcon icon={faCircle} className="text-yellow-500" /> Amber means that Kim has found some sources
            to back up its answer, but it is not fully confident in its accuracy, users should carefully check the
            source documents.
          </li>
          <li>
            <FontAwesomeIcon icon={faCircle} className="text-red-500" /> Red means that either there is no suitable
            source in the documents to answer the question or Kim is unable to answer, but has found some potentially
            relevant sources, which it will display.
          </li>
        </ul>

        {/* Question 4 */}
        <h3 className="mt-4 text-lg font-semibold border-b">What if the answer isn't quite what I am looking for?</h3>

        <p className="py-2">
          If your answer isn't quite what you are looking for, or you want to ask a slightly different question, you
          will need to create a new thread to ask another question.
        </p>
        <p className="text-sm text-gray-500">
          We are currently building a more 'iterative' solution where you can discuss back and forth to get to your
          final output.
        </p>

        <h3 className="mt-4 text-lg font-semibold border-b">
          What if the question I ask isn't answerable by the documents I am looking at?
        </h3>

        <p className="py-2">
          Kim will only look within the documents you provide it. If you ask it a question such as
          <span className="italic">
            “How does the case of Donoghue v Stevenson [1932] AC 562 apply to the issue of duty of care in the
            documents?”
          </span>
          , Kim will not return an answer, since Wexler is deliberately not connected to a legal database, nor does it
          search its training data, nor does it search the internet. This is by design, to limit the chance of
          inaccuracies or erroneous or inexplicable information, Kim is built only to extract information from the
          documents it is pointed at.
        </p>

        {/* Question 6 */}
        <h3 className="mt-4 text-lg font-semibold border-b">Will Kim answer any question from the documents?</h3>
        <p className="py-2">
          Kim's 'answer engine' is based on the facts that have been extracted from the documents. Therefore, questions
          should be around factual information related to the dispute, rather than questions on points of law.
        </p>

        {/* Example questions */}
        <p>
          For example the below questions are example questions from the Enron case which would be good for Kim to
          answer:
        </p>
        <ul className="list-disc ml-5 mt-1 text-sm">
          <li>
            What was the role of the Special Purpose Entities (SPEs) in Enron's financial statements, as revealed in the
            documents?
          </li>
          <li>
            How did Enron's use of mark-to-market accounting contribute to the company's reported financial performance?
          </li>
          <li>What were the key findings of the Powers Report regarding Enron's accounting practices?</li>
          <li>
            How did Enron's corporate culture, as depicted in internal documents, contribute to its eventual collapse?
          </li>
          <li>
            What were the main allegations against Enron's executives as outlined in the internal communications and
            memos?
          </li>
        </ul>

        {/* Question 7 */}
        <h3 className="mt-4 text-lg font-semibold border-b">Can Kim get it wrong?</h3>

        <p className="py-2">
          Kim has undergone extensive testing to increase the accuracy of the answers. However, as with all generative
          AI systems, in complex scenarios it could return an inaccurate answer. Crucially, the human should check the
          source fact and document in the citation to verify the answer's completeness or accuracy. We have designed the
          tool so that answers and sources can be easily checked and verified.
        </p>

        {/* Question 8 */}

        <h3 className="mt-4 text-lg font-semibold border-b">How will Kim develop?</h3>

        <p className="py-2">
          We are building more ways of manipulating the data for specific use cases within Kim. This includes:
        </p>
        <ul className="list-disc ml-5 text-sm">
          <li>
            Building 'sub-chronologies' with different columns based on the data, so that you can make focused
            chronologies and tables based on the facts.
          </li>
          <li>Kim prioritizing 'verified' facts and chronology entries at the top of responses and search results.</li>
          <li>
            Comparing the statements of case: Using Kim to create tables and summaries of agreed and disputed facts,
            with their relevant dates and other information.
          </li>
          <li>
            Witness statement analysis: Comparing witness testimony with facts from the pleadings or the wider
            chronologies.
          </li>
        </ul>

        {/* Contact */}
        <p className="font-bold border py-1 px-2 rounded-lg bg-gray-100 mt-4 w-fit">
          If you have any further questions or issues, please email{' '}
          <a className="font-bold text-blue-500" href="mailto:support@wexler.ai">
            support@wexler.ai
          </a>{' '}
          directly.
        </p>
      </div>
    </div>
  );
};

export default KimQA;
