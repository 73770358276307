import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import Sidebar from 'components/organisms/Sidebar';
import { UserProvider } from 'Contexts/User';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { APIBase } from './api/auth';
import Screens from './screens';

import 'intro.js/introjs.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

const queryClient = new QueryClient();

const BrowserPrompt = () => {
  return (
    <div className="flex lg:hidden justify-center items-center h-screen">
      <p>Wexler is optimised for desktop screens</p>
    </div>
  );
};

function App() {
  return (
    <RequiredAuthProvider
      authUrl={APIBase}
      displayWhileLoading={
        <div className="h-screen w-screen flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      }
      displayIfLoggedOut={<RedirectToLogin />}
    >
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <BrowserPrompt />
            <div className="flex flex-row h-screen font-lota">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
              />
              <Sidebar />
              <Routes>
                <Route path="app/*" element={<Screens />} />
                <Route path="*" element={<Navigate to="/app" />} />
              </Routes>
            </div>
          </BrowserRouter>
        </QueryClientProvider>
      </UserProvider>
    </RequiredAuthProvider>
  );
}

export default App;
