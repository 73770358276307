import React, { useRef, ChangeEvent } from 'react';

interface LabelledTextInputProps {
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  className?: string;
  small?: boolean;
}

const LabelledTextInput: React.FC<LabelledTextInputProps> = ({
  id,
  value,
  onChange,
  label,
  className = '',
  small = false,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = small ? '40px' : '56px';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  return (
    <div className={`relative flex items-center ${className}`}>
      <textarea
        ref={textAreaRef}
        id={id}
        className={`pl-3 w-full focus:outline-none not-italic font-normal rounded-lg resize-none bg-gray-100 overflow-hidden border ${
          small ? 'text-sm h-10 py-1' : 'text-base h-14 py-2'
        }`}
        style={{
          lineHeight: small ? '1.3' : '1.5',
          paddingTop: value ? (small ? '14px' : '18px') : small ? '10px' : '14px',
          transition: 'padding-top 0.3s',
        }}
        onChange={onChange}
        value={value}
        placeholder={''}
        onInput={handleInput}
      />
      <label
        htmlFor={id}
        className={`text-gray-500 absolute left-3 transition-all duration-300 ${
          value ? (small ? 'text-xs' : 'text-xs') : small ? 'text-sm' : 'text-base'
        }`}
        style={{
          zIndex: 1,
          pointerEvents: 'none',
          top: value ? (small ? '2px' : '4px') : '50%',
          transform: value ? 'translateY(0)' : 'translateY(-50%)',
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default LabelledTextInput;
