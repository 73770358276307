import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetMessages = (threadId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getMessages = async () => {
    const response = await fetch(`${APIBaseChronos}/client/case/assistant/messages/${threadId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching messages failed');
    }
    return response.json();
  };

  const {
    isFetching: isLoadingMessages,
    data: responseMessages,
    refetch: refetchMessages,
    error,
  } = useQuery(['messagesResult', threadId], getMessages, {
    cacheTime: 0,
    enabled: false,
  });

  return {
    isLoadingMessages,
    responseMessages,
    refetchMessages,
    error,
  };
};

export default useGetMessages;
