import { Route, Routes, Navigate } from 'react-router-dom';

import Chronos from './Chronos';
import Liability from './Liability';

const Screens = () => {
  return (
    <Routes>
      <Route path="liability" element={<Liability />} />
      <Route path="chronos/*" element={<Chronos />} />
      <Route path="*" element={<Navigate to="/app/chronos" />} />
    </Routes>
  );
};

export default Screens;
