import { useState } from 'react';

import { faChevronCircleLeft, faFile, faMagnifyingGlass, faTimeline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import ChronologyViewer from '../ChronologyViewer';
import DocumentsEditor from '../DocumentsEditor';
import FactsEditor from '../FactsEditor';

const DataView = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const caseId = searchParams.get('caseId');

  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar state

  const handleTabClick = (e: React.MouseEvent, path: string) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const NavItem = ({ title, route, icon }: { title: string; route: string; icon: React.ReactNode }) => {
    const isActive = location.pathname === route.split('?')[0];

    return (
      <div
        className={`hover:bg-gray-200 hover:cursor-pointer py-2 px-3 text-sm flex items-center gap-2 ${
          isActive ? 'bg-gray-200' : ''
        }`}
        onClick={(e) => handleTabClick(e, route)}
      >
        {icon}
        {title}
      </div>
    );
  };

  return (
    <div className="flex h-[87%] w-full">
      {/* Sidebar container */}
      <div
        className={`bg-white relative h-full border-2 rounded flex flex-col py-1 transition-width duration-500 ease-in-out ${
          isCollapsed ? 'w-0 border-gray-50 border-opacity-100' : 'w-36 border-gray-200 border-opacity-80 mr-2'
        } `} // Dynamically set width and transition
      >
        <div className={`absolute top-[-6px] ${isCollapsed ? 'left-[-8px]' : 'right-[-8px]'}`}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft} // Change icon based on state
            className={`w-5 h-5 text-brandSecondary cursor-pointer ${isCollapsed ? 'rotate-180' : ''}`}
            onClick={toggleSidebar}
          />
        </div>

        {!isCollapsed && ( // Render sidebar content only when it's expanded
          <>
            <div className="px-4 border-b text-gray-600 text-sm pb-1 mt-2">Data</div>
            <NavItem
              title="Facts"
              route={`/app/chronos/case-editor/data/facts?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-700 w-3 h-3" />}
            />
            <NavItem
              title="Documents"
              route={`/app/chronos/case-editor/data/documents?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faFile} className="text-gray-700 w-3 h-3" />}
            />
            <div className="px-4 border-b text-gray-500 text-sm mt-8 pb-1">Outputs</div>
            <NavItem
              title="Chronologies"
              route={`/app/chronos/case-editor/data/chronology?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faTimeline} className="text-gray-700 w-3 h-3" />}
            />
          </>
        )}
      </div>

      {/* Main content area */}
      <div className="transition-all duration-300 w-full px-4 border-2 border-gray-200 border-opacity-80 rounded bg-white overflow-x-auto overflow-y-hidden h-full">
        <Routes>
          <Route path="facts" element={<FactsEditor />} />
          <Route path="documents" element={<DocumentsEditor openSettingsModal={openSettingsModal} />} />
          <Route path="chronology/*" element={<ChronologyViewer />} />
        </Routes>
      </div>
    </div>
  );
};

export default DataView;
