import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

interface ChronosFactResponse {
  event_ids: string[];
}

function useKeyFacts(caseId: string | null | undefined) {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<ChronosFactResponse, Error>(
    ['keyFactIds', caseId],
    async () => {
      const response = await fetch(`${APIBaseChronos}/client/case/fact/keyFacts/${caseId}`, fetchConfigGET);
      if (response.status === 404) {
        return null;
      }
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: true, cacheTime: 0 },
  );
  return { data, error, isLoading, isFetching, refetch };
}

export default useKeyFacts;
